import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import SideBar from "../../components/SideBar";
import { toast } from "react-toastify";
import { Progress } from "reactstrap";

const ModifierVideoScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
    if (!userInfo) {
      navigate("/error");
    }
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      if (data.role === "User") {
        navigate("/home");
      }
    };
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`);
      setModules(data);
    };
    getModules();
    getUser();
  }, [navigate]);

  const { state } = useLocation();

  const video = state.video;
  const module = state.module;

  const [moduleId, setModuleId] = useState(video.module);

  const [videoState, setVideoState] = useState({
    title: video.title,
    description: video.description,
  });

  const [watermarkEnabled, setWatermarkEnabled] = useState(true);

  const [image, setImage] = useState(null);
  const [uploadingVideoId, setUploadingVideoId] = useState(null);
  const [uploadingVideo, setUploadingVideo] = useState(null);

  const [selectedVideos, setSelectedVideos] = useState(null);
  const [loaded, setLoaded] = useState(0);

  const [videoDuration, setVideoDuration] = useState(0);
  const [changedImage, setChangedImage] = useState(false);
  const [deletedImage, setDeletedImage] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const [videoFile, setVideoFile] = useState(null);

  const docInputRef = useRef();

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setChangedImage(true);
    }
  };

  const handlePickerChange = (event) => {
    setModuleId(event.target.value);
  };

  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 10) {
      toast.error("Maximum 10 files is allowed");
      event.target.value = null;
      return false;
    } else {
      let err = "";
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880000000000000000) {
          // 50 MB
          err += files[i].name + ", ";
        }
      }
      if (err !== "") {
        // error caught
        event.target.value = null;
        toast.error(err + " is/are too large. Please select file size < 50Mb");
      }
    }
    return true;
  };

  const fileChangeHandler = (event) => {
    const files = event.target.files;
    const ACCEPT = video.type === "pdf" ? ["pdf"] : ["mp4", "avi", "mov", "wmv"];
    if (!files?.length || !ACCEPT.includes(files[0].name.split(".").pop())) {
      setVideoFile(null);
      docInputRef.current.value = "";
      if (files?.length) toast.error("Ce type de document n'est pas accepté");
      return;
    }
    if (maxSelectFile(event)) {
      setSelectedVideos(files);
      setLoaded(0);
    }
    let videoEl = document.createElement("video");
    videoEl.preload = "metadata";
    videoEl.onloadedmetadata = function () {
      window.URL.revokeObjectURL(videoEl.src);
      setVideoDuration(videoEl.duration);
    };
    setVideoFile(event.target.files[0]);
    videoEl.src = URL.createObjectURL(event.target.files[0]);
  };

  function getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 4000);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

  const uploadFile = useCallback(
    async (data) => {
      let formData = new FormData();
      Object.getOwnPropertyNames(data).forEach((key) => {
        if (data[key]) formData.append(key, data[key]);
      });
      try {
        await axios.put(`${baseUrl}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (video.type !== "pdf") {
          setUploadingVideoId(video._id);
          setUploadingVideo({ parts: 1, loadedParts: 0, percent: 0 });
        } else {
          toast.success("Document successfully uploaded");
          setTimeout(() => {
            navigate("/add-video");
            setTimeout(() => window.location.reload(), 1000);
          }, 3000);
          return;
        }
      } catch (error) {
        toast.error("Unable to upload video, error: " + error);
      }
      setIsloading(false);
    },
    [video, navigate]
  );

  useEffect(() => {
    if (uploadingVideoId) {
      const interval = setInterval(async () => {
        const { data } = await axios.get(`${baseUrl}/api/upload/${uploadingVideoId}/video`);
        const total = Math.max(data.parts, 1);
        if (total === data.loadedParts) {
          setUploadingVideoId(null);
          setUploadingVideo(null);
          toast.success("Video successfully uploaded");
          setTimeout(() => {
            navigate("/add-video");
            setTimeout(() => window.location.reload(), 1000);
          }, 3000);
          return;
        }
        setUploadingVideo({
          parts: total,
          loadedParts: data.loadedParts,
          percent: Math.floor((data.splittingPercent + (data.loadedParts * 100) / total) / 2),
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [uploadingVideoId, navigate]);

  const handleUpload = async () => {
    if (!videoState.description || !videoState.title || !moduleId) return;
    setIsloading(true);
    const cover = !videoFile || videoFile.type === "application/pdf" ? null : await getVideoCover(videoFile, 1.5);
    uploadFile({
      _id: video._id,
      title: videoState.title,
      description: videoState.description,
      watermarkEnabled,
      videoFile: videoFile,
      module: moduleId,
      image,
      cover,
      deletedImage,
      time: videoDuration > 0 ? videoDuration : null,
      type: videoFile ? (videoFile.type === "application/pdf" ? "pdf" : "video") : null,
    });
  };

  const [modules, setModules] = useState([]);

  return (
    <div className="d-flex">
      <SideBar />
      {modules && (
        <>
          <div className="container main-admin">
            <div className="form-group"></div>
            {video.type === "pdf" ? <h5>Modifier le PDF</h5> : <h5>Modifier la vidéo</h5>}

            <label className="form-label">Titre</label>
            <input
              type="text"
              value={videoState.title}
              className="form-control"
              onChange={(e) => setVideoState({ ...videoState, title: e.target.value })}
            />

            <label className="form-label">Description</label>
            <input
              type="text"
              value={videoState.description}
              className="form-control"
              onChange={(e) => setVideoState({ ...videoState, description: e.target.value })}
            />

            <div>
              <p>Choisir le module auquel vous souhaitez associer vos vidéos</p>
              <select className="form-select" aria-label="Default select example" onChange={handlePickerChange}>
                <option key={0} value=""></option>
                {modules.map((m, index) => {
                  return (
                    <option key={index + 1} value={m._id} selected={module?.title && m.title === module.title}>
                      {m.title}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <div className="my-2">Image</div>
              {(imagePreview || (!deletedImage && video.hasImage)) && (
                <>
                  <img
                    src={imagePreview || `${baseUrl}/api/upload/${video._id}/image`}
                    alt="preview"
                    className="preview-img img-thumbnail mb-3"
                  />
                  <button
                    className="btn btn-danger mx-5"
                    onClick={() => {
                      setImagePreview(null);
                      setImage(null);
                      setDeletedImage(true);
                      document.getElementById("uploadCaptureInputFile").value = "";
                    }}
                  >
                    Supprimer l'image
                  </button>
                </>
              )}
            </div>
            <div>
              <input type="file" onChange={handleChange} accept="image/*" id="uploadCaptureInputFile" />
            </div>

            <div class="form-check form-switch my-3">
              <input
                className="form-check-input"
                name="watermark"
                type="checkbox"
                id="watermark"
                value={watermarkEnabled}
                checked={watermarkEnabled}
                onChange={() => setWatermarkEnabled((enabled) => !enabled)}
              />
              <label class="form-check-label" for="watermark">
                Activer le watermark
              </label>
            </div>

            <form method="post" name="videoUpload" action="/api/upload" id="#" encType="multipart/form-data">
              <div className="form-group files">
                {video.type === "pdf" ? (
                  <label>Veuillez uploader votre fichier PDF</label>
                ) : (
                  <label>Veuillez uploader vos vidéos</label>
                )}
                <input
                  ref={docInputRef}
                  type="file"
                  name="file"
                  id="upload-videos"
                  accept={video.type === "pdf" ? ".pdf" : ".mp4,.avi,.mov,.wmv"}
                  className="form-control"
                  multiple="multiple"
                  onChange={fileChangeHandler}
                />
                {uploadingVideo && (
                  <Progress max={100} color="success" value={uploadingVideo.percent} className="mt-4 mb-1">
                    {uploadingVideo.percent}%
                  </Progress>
                )}
              </div>
            </form>

            <div className="my-5">
              {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button className="btn button-bootstrap" onClick={handleUpload}>
                  Modifier
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ModifierVideoScreen;
