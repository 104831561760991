import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { getFullFaceDescription } from "../utils/faceUtil";
import { inputSize } from "../globalData";
import { drawFaceRect } from "../utils/drawFaceRect";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../constants/baseUrl";
import faceRecognition from "../assets/face-recognition.svg";
import { faWarning } from "@fortawesome/free-solid-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@mui/material";
import { useCookies } from "react-cookie";

const CONDITIONS_TEXT = `AVERTISSEMENT DE SÉCURITÉ SUR LA PROTECTION DES DONNÉES, RESÈRVES DE PROPRIETES, AUDIOVISUEL, DROIT A L’IMAGE.



1°) Tous les contenus des cours, les protocoles, les vidéos, les croquis, tous les documents administratifs, techniques, quels que soient leurs supports, sont la propriété de l’Institut Hypoténuse, protégés par le code de la propriété industrielle et intellectuelle.

Leur diffusion, copiage, recopiage, quels qu’en soient les moyens : photographie, film, gravure, photocopie, scanner, écriture, captures, etc… sont interdits. 

Il est formellement interdit de filmer, photographier, enregistrer les cours pendant le déroulement de ceux-ci. Cette interdiction s'applique aussi aux cours en ligne (vidéos, croquis, documents, visioconférence,...).



2°) Toute diffusion publique d’images, de photos, d’enregistrements, faisant état des contenus appartenant à l’Institut Hypoténuse constituerait une violation des droits de propriété industrielle et intellectuelle et entrainerait des poursuites pénales.



3°) Nous vous informons que cette plateforme contient des éléments de détection de tentative de fraude et que l’ensemble des supports (vidéos, croquis) contiennent des sécurités numériques traçables.

Votre acceptation d’utilisation des supports présents sur ce site nous autorise de facto à la collecte et l’utilisation de ces données dans le cadre de la sécurité et de la protection de nos droits de propriété industrielle et intellectuelle.



En cas de fraude ou tentative votre compte sera déconnecté et pourra être définitivement suspendu, sans présager des poursuites pénales.



Je reconnais avoir pris connaissance des conditions mentionnées ci-dessus, m’engage à les respecter et autorise la collecte des données.`;

export const UploadFromWebcam = ({ userInfo, email }) => {
  const [conditionModalOpen, setConditionModalOpen] = useState(false);
  const [selectedWebcam, setSelectedWebcam] = useState();
  const [error, setError] = useState(null);
  const [imageSaved, setImageSaved] = useState(false);
  const [fullDesc, setFullDesc] = useState(null);
  const [faceDescriptor, setFaceDescriptor] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [waitText, setWaitText] = useState("");

  const webcamRef = useRef();
  const canvasRef = useRef();
  const [cookies, setCookies] = useCookies(["token"]);

  const navigate = useNavigate();

  useEffect(() => {
    function capture() {
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null &&
        webcamRef.current.video.readyState === 4
      ) {
        setPreviewImage(webcamRef.current.getScreenshot());

        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;

        // Set canvas height and width
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        // 4. TODO - Make Detections
        // e.g. const obj = await net.detect(video);

        // Draw mesh
        getFullFaceDescription(webcamRef.current.getScreenshot(), inputSize)
          .then((data) => {
            if (data?.length > 0 && data[0]?.descriptor) {
              setFullDesc(data);
              setFaceDescriptor(data[0]?.descriptor);
              setWaitText("");
            }
          })
          .catch((err) => {
            setWaitText(
              "Preparing face matcher and device setup, please wait..."
            );
          });
        const ctx = canvasRef.current.getContext("2d");

        drawFaceRect(fullDesc, ctx);
      }
    }

    if (!conditionModalOpen) {
      const interval = setInterval(() => {
        capture();
      }, 700);

      return () => clearInterval(interval);
    }
  }, [conditionModalOpen, fullDesc]);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (image) => {
    setError(null);
    setIsLoading(true);
    let formData = new FormData();
    formData.append("_id", userInfo._id);
    formData.append("firstConnexion", false);
    formData.append("image", previewImage);
    formData.append("detections", faceDescriptor.toString());

    axios
      .put(`${baseUrl}/api/users/profile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async () => {
        if (imageSaved) return;
        const { data } = await axios.post(`${baseUrl}/api/users/login`, {
          email: email,
        });
        if (imageSaved) return;
        if (!cookies.token) {
          localStorage.setItem("userInfoVideoPlateforme", JSON.stringify(data));
          axios.defaults.headers.common["Authorization"] = data.token;
          setCookies("token", data.token, { path: "/" });
          localStorage.setItem("token", data.token);
          setIsLoading(false);
          notify();
          setImageSaved(true);
          setConditionModalOpen(false);
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        }
      });
  };

  const notify = () => {
    toast.success("Votre image a bien été uploadée !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div className="camera-container">
      <div className="webcam-container">
        <Webcam
          muted={true}
          ref={webcamRef}
          audio={false}
          width="100%"
          height="100%"
          screenshotFormat="image/jpeg"
          videoConstraints={{
            deviceId: selectedWebcam,
          }}
          mirrored
        />
        <canvas ref={canvasRef} className="canvas-webcam" />
        <img src={faceRecognition} alt="" className="face-recongition-image" />
      </div>
      {imageSaved && (
        <div className="image-saved-container">
          <p className="validate">Votre empreinte a bien été sauvegardée !</p>
        </div>
      )}
      {previewImage && !imageSaved && (
        <div className="preview-img-container">
          <p className="explication-text">
            Veuillez positionner votre visage devant votre camera au sein du
            carré vert. Une fois l'image ci-dessous correcte, appuyer sur
            valider.
          </p>
          <img
            src={previewImage}
            alt="Capture"
            style={{ width: "200px", height: "200px" }}
          />
          {isLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className="validate-container">
              {error && (
                <p className="error" style={{ marginTop: 2 }}>
                  <FontAwesomeIcon icon={faWarning} className="warning-icon" />
                  {error}
                </p>
              )}
              <div
                className="suivant-button margin-auto"
                onClick={() => {
                  if (!faceDescriptor) {
                    return toast.warning(
                      "Aucun visage n'a été détécté, veuillez recommencer"
                    );
                  }
                  setConditionModalOpen(true);
                }}
              >
                <p>Valider</p>
              </div>
            </div>
          )}
        </div>
      )}
      <Dialog
        open={conditionModalOpen}
        onClose={() => setConditionModalOpen(false)}
      >
        <div style={{ padding: 20 }}>
          {CONDITIONS_TEXT.split("\n").map((text) => (
            <div style={{ marginTop: 5 }}>{text}</div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            margin: 20,
          }}
        >
          <button
            style={{ borderRadius: 10, marginRight: 5 }}
            disabled={isLoading}
            onClick={() => setConditionModalOpen(false)}
          >
            Annuler
          </button>
          {isLoading ? (
            <div className="spinner-border text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <button
              style={{
                borderRadius: 10,
                backgroundColor: "dodgerblue",
                color: "white",
              }}
              onClick={() => {
                handleUpload(previewImage);
              }}
            >
              J'accepte les conditions
            </button>
          )}
        </div>
      </Dialog>
    </div>
  );
};
