import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import React, { useEffect, useState } from "react";
import { faThLarge } from "@fortawesome/free-solid-svg-icons/faThLarge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../constants/baseUrl";
import logo from "../assets/LOGO-HYPOTENUSE2-removebg-preview.png";

const SideBar = () => {
  const DashboardIcon = () => {
    return <FontAwesomeIcon icon={faThLarge} />;
  };
  const FormationIcon = () => {
    return <FontAwesomeIcon icon={faGraduationCap} />;
  };
  const CandidateIcon = () => {
    return <FontAwesomeIcon icon={faMedal} />;
  };
  const BanniereIcon = () => {
    return <FontAwesomeIcon icon={faCalendarAlt} />;
  };
  const MailIcon = () => {
    return <FontAwesomeIcon icon={faGear} />;
  };
  const DisconnectIcon = () => {
    return <FontAwesomeIcon icon={faLock} />;
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUserInfos = async () => {
      const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUser(data);
    };
    getUserInfos();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ProSidebar>
      {user && (
        <>
          <img src={logo} alt="" className="w-25 mx-auto mt-5" />
          <h5 className="text-center mt-3 mb-5">Bonjour {capitalizeFirstLetter(user.firstName)}</h5>
          <Menu iconShape="square">
            <MenuItem icon={<DashboardIcon />}>
              Gérer les utilisateurs <Link to="/admin" />
            </MenuItem>
            <MenuItem icon={<FormationIcon />}>
              Importer les utilisateurs <Link to="/import-users" />
            </MenuItem>
            <MenuItem icon={<CandidateIcon />}>
              Gérer les modules <Link to="/add-module" />
            </MenuItem>
            <MenuItem icon={<BanniereIcon />}>
              Gérer les vidéos/PDF <Link to="/add-video" />
            </MenuItem>
            <MenuItem icon={<MailIcon />}>
              Configuration <Link to="/configuration" />
            </MenuItem>
            <MenuItem icon={<DisconnectIcon />}>
              Utilisateurs déconnectés <Link to="/admin/disconnected" />
            </MenuItem>
          </Menu>
        </>
      )}
    </ProSidebar>
  );
};

export default SideBar;
