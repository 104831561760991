import { Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import {
    isFaceDetectionModelLoaded,
    isFeatureExtractionModelLoaded,
    isFacialLandmarkDetectionModelLoaded,
} from './faceUtil';
export default ({ errorMessage }) => (
    <div>

    </div>
);
