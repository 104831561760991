import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tabs, Tab } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { Progress } from "reactstrap";

import SideBar from "../../components/SideBar";
import { baseUrl } from "../../constants/baseUrl";
import { useNavigate } from "react-router-dom";
import InnerVideoCardAdmin from "../../components/admin/InnerVideoCardAdmin"; // use npm published version

const ACCEPT = [".pdf", ".mp4", ".avi", ".mov", ".wmv"];

const AddVideoScreen = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [watermarkEnabled, setWatermarkEnabled] = useState(true);
  const [uploadingVideoId, setUploadingVideoId] = useState(null);
  const [uploadingVideo, setUploadingVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [videos, setVideos] = useState([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [error, setError] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [focus, setFocus] = useState(false);

  const docInputRef = useRef();

  useEffect(() => {
    const getAllVideos = async () => {
      const { data } = await axios.get(`${baseUrl}/api/upload`);
      setVideos(data);
    };
    getAllVideos();
  }, []);

  const uploadFile = useCallback(async (data) => {
    let formData = new FormData();
    Object.getOwnPropertyNames(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    try {
      const { data: videoInfos } = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.type !== "pdf") {
        setUploadingVideoId(videoInfos._id);
        setUploadingVideo({ parts: 1, loadedParts: 0, percent: 0 });
      } else {
        toast.success("Pdf successfully uploaded");
        setTimeout(() => window.location.reload(), 3000);
      }
    } catch (error) {
      toast.error("Unable to upload video, error: " + error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (uploadingVideoId) {
      const getStatus = async () => {
        const { data } = await axios.get(`${baseUrl}/api/upload/${uploadingVideoId}/video`);
        const total = Math.max(data.parts, 1);
        if (total === data.loadedParts) {
          setUploadingVideoId(null);
          setUploadingVideo(null);
          toast.success("Video successfully uploaded");
          setTimeout(() => window.location.reload(), 3000);
          return;
        }
        setUploadingVideo({
          parts: total,
          loadedParts: data.loadedParts,
          percent: Math.floor((data.splittingPercent + (data.loadedParts * 100) / total) / 2),
        });
        setTimeout(getStatus, 2000);
      };

      const timeout = setTimeout(getStatus, 2000);
      return () => clearTimeout(timeout);
    }
  }, [uploadingVideoId]);

  const fileChangeHandler = useCallback(
    (event) => {
      const files = event.target.files;
      if (!files?.length || !ACCEPT.includes("." + files[0].name.split(".").pop())) {
        setVideoFile(null);
        docInputRef.current.value = "";
        if (files?.length) toast.error("Ce type de document n'est pas accepté");
        return;
      }
      let video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setVideoDuration(video.duration);
      };
      setVideoFile(files[0]);
      video.src = URL.createObjectURL(files[0]);
    },
    [docInputRef]
  );

  const getVideoCover = useCallback((file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 4000);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }, []);

  const handleUpload = useCallback(async () => {
    if (!description || !title || !moduleId || !videoFile) return setError(true);
    setLoading(true);
    const cover = videoFile.type === "application/pdf" ? null : await getVideoCover(videoFile, 1.5);
    setLoading(true);
    uploadFile({
      title,
      description,
      watermarkEnabled,
      video: videoFile,
      module: moduleId,
      image,
      cover,
      time: videoDuration,
      type: videoFile.type === "application/pdf" ? "pdf" : "video",
    });
  }, [description, title, moduleId, videoFile, image, videoDuration, watermarkEnabled, uploadFile, getVideoCover]);

  //----------------- Image upload --------------//

  const handleChange = useCallback((e) => {
    if (e.target.files[0]) {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  }, []);

  const handlePickerChange = useCallback((event) => {
    setModuleId(event.target.value);
  }, []);

  const navigate = useNavigate();
  const [modules, setModules] = useState([]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
    if (!userInfo) {
      navigate("/error");
    }
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`);
      setModules(data);
    };
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      if (data.role === "User") {
        navigate("/home");
      }
    };
    getUser();
    getModules();
  }, [navigate]);

  const getModuleById = useCallback(
    (id) => {
      for (let i = 0; i < modules.length; i++) {
        if (modules[i]._id === id) return modules[i];
      }
      return null;
    },
    [modules]
  );

  const filterVideos = useMemo(
    () =>
      videos
        .filter((video) => searchTerm === "" || video.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((video) => ({
          ...video,
          module_obj: getModuleById(video.module),
        })),
    [searchTerm, videos, getModuleById]
  );

  const [addMedia, setAddMedia] = useState(false);

  const moduleTabs = useMemo(
    () =>
      modules.map((module, index) => (
        <Tab key={index} eventKey={module.title} title={module.title}>
          {videos.filter((obj) => {
            return obj.module === module._id;
          }).length === 0 ? (
            <p>Vous n'avez pas de média dans ce module </p>
          ) : (
            <p>
              Vous avez{" "}
              {
                videos.filter((obj) => {
                  return obj.module === module._id;
                }).length
              }{" "}
              média(s) dans ce module
            </p>
          )}
          {videos
            .filter((obj) => {
              return obj.module === module._id;
            })
            .map((video, index) => {
              return (
                <>
                  <InnerVideoCardAdmin video={video} key={index} module={module} />
                </>
              );
            })}
        </Tab>
      )),
    [modules, videos]
  );

  return (
    <div className="d-flex">
      <SideBar />
      <div className="main-admin container px-5">
        <h5 className="text-center my-5">Création d'un média</h5>
        <div className="btn button-bootstrap mr-5 my-5" onClick={() => setAddMedia(!addMedia)}>
          Ajouter un média
        </div>

        {addMedia && (
          <>
            <div>
              <input
                type="text"
                name="title"
                placeholder="Titre"
                className="form-control my-3"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Description"
                name="description"
                className="form-control"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>

            <div>
              <p>Choisir le module auquel vous souhaitez associer vos vidéos</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                autoWidth
                value={moduleId}
                onChange={handlePickerChange}
              >
                {modules.map((module, index) => {
                  return (
                    <MenuItem value={module._id} key={index}>
                      {module.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <hr />
            <h5>Choix de l'image</h5>
            <p className="my-5">
              L'image de votre vidéo sera ajoutée automatiquement. <br />
              Si vous souhaitez en ajouter une manuellement, appuyez sur le bouton ci-dessous.
            </p>
            <input type="file" onChange={handleChange} accept="image/*" id="uploadCaptureInputFile" />

            {previewImage && (
              <div>
                <img src={previewImage} alt="preview" className="preview-img" />
                <button
                  className="btn btn-danger mx-5"
                  onClick={() => {
                    setPreviewImage(null);
                    setImage(null);
                    document.getElementById("uploadCaptureInputFile").value = "";
                  }}
                >
                  Supprimer l'image
                </button>
              </div>
            )}

            <hr />
            <h5>Choix du média</h5>
            <div className=" mt-5">
              <div className="form-group"></div>
              <input
                type="checkbox"
                name="watermark"
                id="watermark"
                value={watermarkEnabled}
                checked={watermarkEnabled}
                style={{ marginRight: 10 }}
                onChange={() => setWatermarkEnabled((enabled) => !enabled)}
              />
              <label for="watermark">Activer le watermark</label>
              <form method="post" name="videoUpload" action="/api/upload" id="#" encType="multipart/form-data">
                <div className="form-group files">
                  <>
                    <label>Veuillez uploader votre vidéo ou votre fichier PDF ci-dessous</label>
                    <input
                      ref={docInputRef}
                      type="file"
                      name="file"
                      id="upload-videos"
                      accept={ACCEPT.join(",")}
                      className="form-control"
                      onChange={fileChangeHandler}
                    />
                    {uploadingVideo && (
                      <Progress max={100} color="success" value={uploadingVideo.percent} className="mt-4 mb-1">
                        {uploadingVideo.percent}%
                      </Progress>
                    )}
                    <button
                      type="button"
                      className={"btn button-bootstrap btn-block" + (uploadingVideo ? "" : " mt-4")}
                      disabled={loading}
                      onClick={handleUpload}
                    >
                      {loading ? <CircularProgress size={"2em"} /> : "Uploader votre vidéo ou votre fichier PDF"}
                    </button>
                  </>

                  {error && (
                    <div className="alert alert-danger my-5" role="alert">
                      Veuillez remplir tous les champs
                    </div>
                  )}
                </div>
              </form>
            </div>
          </>
        )}

        <div className="input-group mb-3 w-100 d-flex justify-content-between">
          <div className="w-50">
            <input
              type="text"
              className="form-control w-100"
              placeholder="Rechercher..."
              aria-label="Username"
              onFocus={() => setFocus(true)}
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {focus && (
            <div className="alert-danger px-5 rounded" onClick={() => setFocus(false)}>
              Fermer la barre de recherche
            </div>
          )}
        </div>

        {focus ? (
          <>
            {videos.length !== 0 ? (
              <div>
                {filterVideos.length !== 0 ? (
                  <>
                    {filterVideos.map((video, index) => {
                      return (
                        <InnerVideoCardAdmin video={video} module={video.module_obj} key={index} setFocus={setFocus} />
                      );
                    })}
                  </>
                ) : (
                  <p>Aucune vidéo ne correspond a votre recherche</p>
                )}
              </div>
            ) : (
              <p>Vous n'avez aucune vidéo pour ce module, veuillez en uploader</p>
            )}
          </>
        ) : (
          <>
            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
              {moduleTabs}
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};

export default AddVideoScreen;
