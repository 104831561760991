import React, { useEffect, useMemo, useState } from "react";
import cross from "../assets/cancel.png";
import { Modal } from "react-bootstrap";
import { baseUrl } from "../constants/baseUrl";
import { pdfjs } from "react-pdf";
import { isNaN } from "formik";
import { Viewer } from "@react-pdf-viewer/core";
import ReactHlsPlayer from "react-hls-player";
import { useCookies } from "react-cookie";
import pdf_file from "../assets/pdf_file.png";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

const VideoCard = ({ video }) => {
  const [cookies] = useCookies(["token"]);
  function convert(value) {
    //return Math.ceil(value / 60);
    return (value / 60).toFixed(2);
  }

  const minutes = video.type === "pdf" ? null : convert(video.time);

  const isIphone = useMemo(() => /iphone/i.test(navigator.userAgent), []);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // const [allPageNumbers, setAllPageNumbers] = React.useState([]);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   const allPageNumbers = []; // array of numbers
  //   for (let p = 1; p < numPages + 1; p++) {
  //     allPageNumbers.push(p);
  //   }
  //   setAllPageNumbers(allPageNumbers);
  //   setPageNumber(1);
  // }

  useEffect(() => {
    setShowVideo(false);
    setShowPDF(false);
  }, [video]);

  const [showVideo, setShowVideo] = useState(false);
  const [showPDF, setShowPDF] = useState(false);

  const handleCloseVideo = () => setShowVideo(false);
  const handleShowVideo = () => setShowVideo(true);

  const handleClosePDF = () => setShowPDF(false);
  const handleShowPDF = () => setShowPDF(true);

  return (
    <>
      <div className="video-card-container" onClick={video.type === "pdf" ? handleShowPDF : handleShowVideo}>
        <div className="inner-video-card-container">
          {video.type === "pdf" ? (
            <img src={pdf_file} alt="cover" width={"100px"} />
          ) : (
            <div className="img-card-container">
              <img src={`${baseUrl}/api/upload/${video._id}/image`} alt="cover" />
            </div>
          )}
          <div className="title-container ml-5">
            <h5>{video.title}</h5>
            <p>{video.description}</p>
          </div>
        </div>
        <div className="min">{isNaN(minutes) ? <p>-</p> : <p>{minutes}</p>}</div>
        <div className="format">{video.type === "pdf" ? <p>PDF</p> : <p>VIDEO</p>}</div>
      </div>

      <Modal show={showVideo} onHide={handleCloseVideo}>
        <Modal.Body>
          <div className="video-preview-container">
            <img
              src={cross}
              alt=""
              className="cross-img cross-img-card-video"
              data-dismiss="modal"
              onClick={handleCloseVideo}
            />
            {isIphone ? (
              <video width="100%" height="auto" controls autoPlay={false} controlsList="nodownload">
                <source src={`${baseUrl}/api/upload/hls/${video._id}/output.m3u8`} />
              </video>
            ) : (
              <ReactHlsPlayer
                src={`${baseUrl}/api/upload/hls/${video._id}/output.m3u8`}
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
                hlsConfig={{
                  xhrSetup: function (xhr, url) {
                    xhr.setRequestHeader("Authorization", cookies.token);
                  },
                }}
                onError={(event) => console.error(event.target.error.message)}
              />
            )}
            <div className="description-card-video-container">
              <div>
                <h5>{video.title}</h5>
                <p>{video.description}</p>
              </div>
              <div className="format format-card">
                <p>VIDEO</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showPDF} onHide={handleClosePDF} className="modal-pdf">
        <Modal.Body>
          <img
            src={cross}
            alt="cross"
            className="cross-img cross-img-pdf"
            data-dismiss="modal"
            onClick={handleClosePDF}
          />
          <Viewer
            fileUrl={`${baseUrl}/api/upload/${video._id}/pdf`}
            httpHeaders={{ Authorization: cookies.token }}
            renderError={(error) => <div>Impossible de lire le PDF</div>}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoCard;
