import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import Webcam from "react-webcam";
import { getFullFaceDescription } from "../utils/faceUtil";
import { DEFAULT_WEBCAM_RESOLUTION, inputSize } from "../globalData";
import { drawFaceRect } from "../utils/drawFaceRect";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as faceapi from "face-api.js";
import { baseUrl } from "../constants/baseUrl";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";

export const WebcamLogin = ({ userDetails, email }) => {
  const [camWidth, setCamWidth] = useState(DEFAULT_WEBCAM_RESOLUTION.width);
  const [camHeight, setCamHeight] = useState(DEFAULT_WEBCAM_RESOLUTION.height);
  const [selectedWebcam, setSelectedWebcam] = useState();

  const [fullDesc, setFullDesc] = useState(null);

  const [faceDescriptor, setFaceDescriptor] = useState([]);

  const [previewImage, setPreviewImage] = useState("");

  const [waitText, setWaitText] = useState("");

  const [recognizedFace, setRecognizedFace] = useState(false);
  const [error, setError] = useState(false);

  const webcamRef = useRef();
  const canvasRef = useRef();
  const [cookies, setCookies] = useCookies(["token"]);

  const [modelLoaded, setModelsLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68TinyNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => {
        setModelsLoaded(true);
      });
    };
    loadModels();
  }, []);

  useEffect(() => {
    async function capture() {
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null &&
        webcamRef.current.video.readyState === 4
      ) {
        setPreviewImage(webcamRef.current.getScreenshot());

        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;

        // Set canvas height and width
        canvasRef.current.width = width < 500 ? 400 : videoWidth;
        canvasRef.current.height = width < 500 ? 400 : videoHeight;

        // 4. TODO - Make Detections
        // e.g. const obj = await net.detect(video);

        // Draw mesh
        getFullFaceDescription(webcamRef.current.getScreenshot(), inputSize)
          .then((data) => {
            setFullDesc(data);
            setFaceDescriptor(data[0]?.descriptor);
            setWaitText("");
          })
          .catch((err) => {
            setWaitText(
              "Preparing face matcher and device setup, please wait..."
            );
          });
        const ctx = canvasRef.current.getContext("2d");

        drawFaceRect(fullDesc, ctx);

        await loadLabeledImages();
        //const test = await loadLabeledImages()
        //console.log('teet', test)
      }
    }

    if (!recognizedFace) {
      const interval = setInterval(() => {
        capture();
      }, 700);
      return () => clearInterval(interval);
    }
  });

  const [userInfos, setUserInfos] = useState(null);

  useEffect(() => {
    const getUserInfos = async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("userInfoVideoPlateforme")
      );

      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserInfos(data);
    };
    getUserInfos();
  }, []);

  async function loadLabeledImages() {
    let img = document.createElement("img");
    let previewImageHTML = document.createElement("img");
    previewImageHTML.src = previewImage;
    previewImageHTML.crossOrigin = "Anonymous";
    img.src = `${baseUrl}/api/users/${userDetails._id}/user_image`;

    img.crossOrigin = "Anonymous";

    const detections = await faceapi
      .detectSingleFace(img)
      .withFaceLandmarks()
      .withFaceDescriptor();
    const descriptorsObama = [new Float32Array(detections.descriptor)];
    const faceMatcher = new faceapi.FaceMatcher(descriptorsObama);

    const singleResult = await faceapi
      .detectSingleFace(previewImageHTML)
      .withFaceLandmarks()
      .withFaceDescriptor();
    let bestMatch;
    try {
      bestMatch = faceMatcher.findBestMatch(singleResult.descriptor);
    } catch (err) {
      console.log("err", err);
    }

    if (!bestMatch) {
      return;
    }
    if (bestMatch._label !== "unknown") {
      if (recognizedFace) return;
      const { data } = await axios.post(`${baseUrl}/api/users/login`, {
        email: email,
      });
      if (recognizedFace) return;
      setUserInfos(data);
      localStorage.setItem("userInfoVideoPlateforme", JSON.stringify(data));
      if (!cookies.token) {
        axios.defaults.headers.common["Authorization"] = data.token;
        setCookies("token", data.token, { path: "/" });
        localStorage.setItem("token", data.token);
        setRecognizedFace(true);
        navigate("/home");
      }
    } else {
      setError(true);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (
    <>
      {modelLoaded && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Webcam
            muted={true}
            ref={webcamRef}
            audio={false}
            width={width < 500 ? "100%" : camWidth}
            height={width < 500 ? "100%" : camHeight}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              deviceId: selectedWebcam,
            }}
            mirrored
          />
          <canvas
            ref={canvasRef}
            style={{
              position: "absolute",
              textAlign: "center",
              zindex: 8,
              left: width < 500 ? "0%" : "unset",
              top: width < 500 ? "0%" : 0,
              width: width < 500 ? 400 : camWidth,
              height: width < 500 ? 400 : camHeight,
            }}
          />
          {recognizedFace && userInfos ? (
            <div className="validate-user-container">
              <p className="text-center validate validate-login">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="check-circle"
                />
                Bienvenue {capitalizeFirstLetter(userInfos.firstName)} sur la
                plateforme
              </p>
            </div>
          ) : (
            <div className="validate-user-container-blue">
              <p className="text-center validate-blue validate-login">
                Merci de patienter pendant la reconnaissance
              </p>
            </div>
          )}
        </div>
      )}

      {error && (
        <div>
          <h5 className="text-center">
            Désolé votre visage n'a pas été reconnu !
          </h5>
        </div>
      )}
    </>
  );
};
