import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import axios from "axios";
import CardModuleAdmin from "../../components/admin/CardModuleAdmin";
import { baseUrl } from "../../constants/baseUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddModuleScreen = () => {
  const [previewImage, setPreviewImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [modules, setModules] = useState([]);
  const [image, setImage] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
    if (!userInfo) {
      navigate("/error");
    }
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`);
      setModules(data);
    };
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      if (data.role === "User") {
        navigate("/home");
      }
    };
    getModules();
    getUser();
  }, [navigate]);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [error, setError] = useState("");

  const handleUpload = () => {
    if (!title || !description || !image) return setError("Veuillez remplir tous les champs");

    setIsloading(true);
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    axios
      .post(`${baseUrl}/api/admin/modules`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsloading(false);
        toast.success("Le module a été créé");
        setTimeout(() => window.location.reload(), 3000);
      });
  };

  return (
    <div className="d-flex">
      <SideBar />
      <div className="container main-admin">
        <div className="form-group"></div>
        <h5 className="text-center my-5">Création de module</h5>
        <div>
          <input
            type="text"
            name="title"
            placeholder="Titre"
            className="form-control my-3"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        <div>
          <input
            type="text"
            placeholder="Description"
            name="description"
            className="form-control my-3"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </div>

        <hr />
        <h5>Choix de l'image</h5>
        <p className="">Veuillez ajouter une image pour votre module </p>
        <br />
        <input type="file" onChange={handleChange} accept="image/*" id="uploadCaptureInputFile" />

        {previewImage && (
          <div>
            <img src={previewImage} alt="preview" className="preview-img" />
            <button
              className="btn btn-danger mx-5"
              onClick={() => {
                setPreviewImage(null);
                setImage(null);
                document.getElementById("uploadCaptureInputFile").value = "";
              }}
            >
              Supprimer l'image
            </button>
          </div>
        )}

        {isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            <button onClick={handleUpload} className="btn button-bootstrap my-5">
              Créer mon module
            </button>
          </div>
        )}

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="d-flex">
          <h2 className="w-50">Mes modules</h2>
          <div className="input-group mb-3 w-50">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher avec le titre"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {modules.length !== 0 ? (
          <div className="py-5">
            {modules
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (val.title.toLowerCase().includes(searchTerm.toLowerCase())) {
                  return val;
                }
              })
              .map((module, index) => {
                return <CardModuleAdmin module={module} key={index} />;
              })}
          </div>
        ) : (
          <p>Vous n'avez aucun module de créé</p>
        )}
      </div>
    </div>
  );
};

export default AddModuleScreen;
