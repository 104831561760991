import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "../constants/baseUrl";

const FormationCard = ({ formation }) => {
  let navigate = useNavigate();

  return (
    <Card
      className="card-formation"
      onClick={() => {
        navigate(`/module/${formation._id}`, {
          state: {
            formation,
          },
        });
      }}
    >
      <Card.Img variant="top" src={`${baseUrl}/api/modules/${formation._id}/image`} className="card-home-img" />
      <Card.Body className="card-body-hover">
        <Card.Title>{formation.title}</Card.Title>
        <Card.Text>{formation.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default FormationCard;
