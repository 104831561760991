import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import { baseUrl } from "../../constants/baseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const ModifierModuleScreen = () => {
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
    if (!userInfo) {
      navigate("/error");
    }
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      if (data.role === "User") {
        navigate("/home");
      }
    };
    const getUsers = async () => {
      const { data } = await axios.get(`${baseUrl}/api/users`);
      setUsers(data);
    };
    getUsers();
    getUser();
  }, [navigate]);

  const { state } = useLocation();

  const module = state.module;

  const [moduleState, setModuleState] = useState({
    description: module.description,
    title: module.title,
    image: module.image,
  });

  const [image, setImage] = useState(null);

  const [imagePreview, setImagePreview] = useState("");

  const [modify, setModify] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);
    if (image) {
      let formData = new FormData();
      formData.append("_id", module._id);
      formData.append("title", module.title);
      formData.append("description", module.description);
      formData.append("image", image);
      await axios.put(`${baseUrl}/api/modules`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      for (let user of users) {
        await axios.put(`${baseUrl}/api/users/modules`, {
          moduleID: module._id,
          _id: user._id,
          title: moduleState.title,
        });
      }
      setIsLoading(false);
      setModify(true);
      toast.success("Le module a bien été modifié");
      setTimeout(() => {
        navigate("/add-module");
        window.location.reload();
      }, 3000);
    } else {
      await axios.put(`${baseUrl}/api/modules`, {
        _id: module._id,
        title: moduleState.title,
        description: moduleState.description,
      });

      for (let user of users) {
        await axios.put(`${baseUrl}/api/users/modules`, {
          moduleID: module._id,
          _id: user._id,
          title: moduleState.title,
        });
      }
      setIsLoading(false);
      setModify(true);
      toast.success("Le module a bien été modifié");
      setTimeout(() => {
        navigate("/add-module");
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div className="d-flex">
      <SideBar />
      <div className="container main-admin my-5">
        <div className="form-group"></div>
        <h5>Modifier le module</h5>
        <label className="form-label">Titre</label>
        <input
          type="text"
          value={moduleState.title}
          className="form-control"
          onChange={(e) => setModuleState({ ...moduleState, title: e.target.value })}
        />

        <label className="form-label">Description</label>
        <input
          type="text"
          value={moduleState.description}
          className="form-control"
          onChange={(e) => setModuleState({ ...moduleState, description: e.target.value })}
        />
        <div>
          <label className="form-label">Image</label>
          <div>
            <img
              src={imagePreview || `${baseUrl}/api/modules/${module._id}/image`}
              alt="preview"
              className="preview-img"
            />
          </div>
        </div>
        <div>
          <input type="file" onChange={handleChange} accept="image/*" id="uploadCaptureInputFile" />
        </div>

        {modify ? (
          <div className="alert alert-success" role="alert">
            Votre module a bien été modifié !
          </div>
        ) : (
          <div className="my-5">
            {isLoading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button className="btn button-bootstrap" onClick={handleUpload}>
                Modifier
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifierModuleScreen;
