export const convertToMonth = (seconds) => {
  const SECONDS_IN_MONTH = 30.44 * 24 * 60 * 60;

  // Calculate the number of months
  const months = seconds / SECONDS_IN_MONTH;

  return months.toFixed(2);
};

export function addMonthsToDate(date, monthsToAdd) {
  const inputDate = new Date(date);
  const newDate = new Date(inputDate);

  newDate.setMonth(inputDate.getMonth() + monthsToAdd);

  const day = newDate.getDate();
  const month = newDate.getMonth() + 1; // Months are 0-based, so we add 1
  const year = newDate.getFullYear();

  // Ensure day and month are formatted with leading zeros if necessary
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return `${formattedDay}/${formattedMonth}/${year}`;
}

export function formatDateToDDMMYYYY(isoDate) {
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based, so we add 1
  const year = date.getFullYear();

  // Ensure day and month are formatted with leading zeros if necessary
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return `${formattedDay}/${formattedMonth}/${year}`;
}

export function secondsToHHMM(seconds) {
  if (isNaN(seconds)) {
    return "Invalid input";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}h${formattedMinutes}`;
}

export function minutesToHHMM(minutes) {
  if (isNaN(minutes)) {
    return "Invalid input";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");

  return `${formattedHours}h${formattedMinutes}`;
}

export function addMonthsToDateAndReturnDate(date, monthsToAdd) {
  const inputDate = new Date(date);
  const newDate = new Date(inputDate);

  newDate.setMonth(inputDate.getMonth() + monthsToAdd);

  return newDate;
}

export function isDateAfter(dateStr1, dateStr2) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  return date1 > date2;
}

export function subtractSecondsAndFormat(seconds1, seconds2) {
  // Calculate the difference in seconds
  const timeDifference = seconds1 - seconds2;
  // Ensure the result is not negative
  if (timeDifference < 0) {
    throw new Error("Result is negative; ensure the first input is greater.");
  }

  // Calculate hours and minutes
  const hours = Math.floor(timeDifference / 3600); // 3600 seconds in an hour
  const minutes = timeDifference % 60;

  // Format the result as HH:MM
  const result = `${hours.toString().padStart(2, "0")}h${minutes
    .toString()
    .padStart(2, "0")}`;

  return result;
}

export function secondsToMinutes(seconds) {
  // Convert seconds to minutes
  const minutes = Number(seconds) / 60;

  return minutes;
}

export function addMonthsAndCalculateDifference(startDateStr, monthsToAdd) {
  // Parse the input date string to create a JavaScript Date object
  const startDate = new Date(startDateStr);

  // Calculate the new date by adding the specified number of months
  const endDate = new Date(startDateStr);
  endDate.setMonth(startDate.getMonth() + monthsToAdd);

  // Calculate the difference in months
  const startYear = new Date().getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = new Date().getMonth();
  const endMonth = endDate.getMonth();

  // Calculate the number of months between the start and end dates
  const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

  return monthDifference;
}
