import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../../components/SideBar";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import {
  addMonthsToDate,
  convertToMonth,
  formatDateToDDMMYYYY,
  secondsToMinutes,
} from "../../utils/convertDate";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
const AdminScreen = () => {
  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    if (!userInfo) {
      navigate("/error");
    }
    const getUsers = async () => {
      const { data } = await axios.get(`${baseUrl}/api/users`);
      setUsers(data);
    };
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`);
      setModules(data);
      setUserModules(new Array(data.length).fill(0).map(() => new Object()));
    };
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserInfos(data);
      if (data.role === "User") {
        navigate("/home");
      }
    };
    getUser();
    getModules();
    getUsers();
  }, []);

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [connectionTime, setConnectionTime] = useState(0);
  const [role, setRole] = useState("");
  const [userInfos, setUserInfos] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [userModules, setUserModules] = useState([]);
  const [unauthorised, setUnauthorised] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  let adminModules = [];

  const calculateTotalTimeSpentOnModules = (modules) => {
    let time = 0;
    for (let module of modules) {
      time += module.timeSpentOnModule;
    }
    return (time / 60).toFixed(2);
  };

  // Get User for the TAB
  const rows_users = users.map((user) => {
    return {
      id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      connectionTime: user.connectionTime,
      email: user.email,
      role: user.role,
      session: user.session,
      numberOfDeconnections: user.numberOfDeconnections,
      modules: user.modules,
      password: user.password,
      createdAt: `${formatDateToDDMMYYYY(user.createdAt)}`,
      totalVisionningTime: `${calculateTotalTimeSpentOnModules(user.modules)}`,
      endAt: `${addMonthsToDate(user.createdAt, user.connectionTime)}`,
    };
  });
  // Use state for update component after action
  const [table, settable] = useState([]);

  const deleteUser = async (id, user) => {
    console.log("ok chef, i delete the user", id);
    const we_delete = await axios.delete(`${baseUrl}/api/users/${id}`);
    settable([...table, we_delete]);
  };

  const handleDeleteMultipleUsers = async () => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
      _id: userInfo._id,
    });
    if (data.role !== "Admin") {
      toast.error("Vous n'avez pas les droits");
      return;
    } else {
      for (let data of selectedRows) {
        await deleteUser(data.id);
      }
      toast.success("Utilisateur(s) supprimé(s)");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const resetFacial = async (userId) => {
    await axios.put(`${baseUrl}/api/users/profile`, {
      _id: userId,
      firstConnexion: true,
      detections: "nouvelle",
      image: "Aucune",
    });
    toast.success("L'empreinte faciale a bien été réinitialisée");
  };

  const columns = [
    { field: "lastName", headerName: "Nom", width: 150, editable: true },
    { field: "firstName", headerName: "Prenom", width: 150, editable: true },
    {
      field: "connectionTime",
      headerName: "Duree (M)",
      width: 80,
      editable: true,
    },
    { field: "createdAt", headerName: "Début", width: 100, editable: true },
    { field: "endAt", headerName: "Fin", width: 100, editable: true },
    { field: "email", headerName: "Email", width: 150, editable: true },
    {
      field: "role",
      headerName: "Access",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Admin", "Manager", "User"],
    },
    { field: "session", headerName: "Session", width: 100, editable: true },
    {
      field: "password",
      headerName: "Mot de passe",
      width: 150,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Reset facial",
      flex: "∞",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RestartAltIcon />}
          onClick={() => {
            console.log("parms", params);
            resetFacial(params.id);
          }}
          label="ResetIcon"
        />,
      ],
    },
    {
      field: "totalVisionningTime",
      headerName: "Total visionnage (M)",
      width: 150,
      editable: true,
    },
  ];

  const updateFieldChanged = (index, moduleId, title) => (e) => {
    let newArr = [...userModules]; // copying the old datas array
    newArr[index].time = Number(e.target.value);
    newArr[index].timeSpentOnModule = 0;
    newArr[index].module = moduleId;
    newArr[index].title = title; // replace e.target.value with whatever you want to change it to
    setUserModules(newArr);
  };

  let modulesError = "";
  const handleSubmit = async (e) => {
    setError(false);
    for (let i = 0; i < modules.length; i++) {
      adminModules.push({
        time: 1,
        timeSpentOnModule: 1,
        module: modules[i]._id,
        title: modules[i].title,
      });
    }
    if (role === "User") {
      for (let obj of userModules) {
        if (Object.keys(obj).length !== 4) {
          modulesError = "Veuillez remplir le temps pour chacun des modules";
          setError("Veuillez remplir le temps pour chacun des modules");
        }
      }
    }
    e.preventDefault();
    console.log(error);
    if (!firstName || !lastName || !email || !connectionTime || !role) {
      setError("Veuillez remplir tous les champs");
    } else if (role !== "User" && userInfos.role !== "Admin") {
      setUnauthorised(true);
      setError(false);
    } else if (!modulesError) {
      setUnauthorised(false);
      if (role === "User") {
        await axios.post(`${baseUrl}/api/admin/create-user`, {
          email,
          firstName,
          lastName,
          connectionTime,
          role,
          modules: userModules,
        });
        setDone(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        await axios.post(`${baseUrl}/api/admin/create-user`, {
          email,
          firstName,
          lastName,
          connectionTime,
          role,
          modules: adminModules,
        });
        setDone(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const handlePickerChange = (event) => {
    setRole(event.target.value);
  };

  const handleCellEditCommit = async (params) => {
    const { id, field, value } = params;
    if (field === "password") {
      if (value.length <= 4 && value !== "") {
        toast.error(
          "Le mot de passe doit être composé d'au moins 4 caractères."
        );
        params.value = "";
        return;
      }
    }
    if (field[0] === "6") {
      try {
        const response = await axios.put(`${baseUrl}/api/users/modules`, {
          _id: id,
          moduleID: field,
          time: value,
        });

        if (response.status === 200) {
          const { data } = await axios.get(`${baseUrl}/api/users`);
          setUsers(data);

          console.log("Module updated");
        } else {
          console.error("Failed to update field in the database.");
        }
      } catch (error) {
        console.error("An error occurred while updating the field:", error);
      }
    } else {
      try {
        const response = await axios.put(`${baseUrl}/api/users/profile`, {
          _id: id,
          [field]: value,
        });

        if (response.status === 200) {
          const { data } = await axios.get(`${baseUrl}/api/users`);
          setUsers(data);
          console.log("updated");
        } else {
          console.error("Failed to update field in the database.");
        }
      } catch (error) {
        console.error("An error occurred while updating the field:", error);
      }
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  return (
    <div className="d-flex">
      <SideBar />
      <div className="main-admin px-5">
        <h1 className="text-center my-5">Liste des utilisateurs</h1>

        {addUser ? (
          <RemoveIcon onClick={() => setAddUser(false)} />
        ) : (
          <AddIcon onClick={() => setAddUser(true)} />
        )}

        {addUser && (
          <form>
            <div className="mb-3 mt-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3 mt-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Prénom
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mb-3 mt-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Nom
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Durée (en mois)
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                onChange={(e) => setConnectionTime(Number(e.target.value))}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label me-4"
              >
                Rôle:
              </label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                style={{ width: "200px" }}
                value={role}
                onChange={handlePickerChange}
              >
                <MenuItem value={"User"} key={1}>
                  User
                </MenuItem>
                <MenuItem value={"Manager"} key={2}>
                  Manager
                </MenuItem>
                <MenuItem value={"Admin"} key={3}>
                  Admin
                </MenuItem>
              </Select>
            </div>

            {role === "User" && (
              <>
                {modules.map((module, index) => {
                  return (
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Temps de connexion au module {module.title} (en minutes)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        onChange={updateFieldChanged(
                          index,
                          module._id,
                          module.title
                        )}
                      />
                    </div>
                  );
                })}
              </>
            )}

            <button
              type="submit"
              className="btn btn-success"
              onClick={(e) => handleSubmit(e)}
            >
              Ajouter
            </button>
            {unauthorised && (
              <div className="alert alert-danger my-3" role="alert">
                Vous ne disposez pas des droits nécessaires pour ajouter un
                utilisateur avec le statut de {role} !
              </div>
            )}
            {error && (
              <div className="alert alert-danger my-3" role="alert">
                {error}
              </div>
            )}
            {modulesError && (
              <div className="alert alert-danger my-3" role="alert">
                {modulesError}
              </div>
            )}
          </form>
        )}

        {done && (
          <div className="alert alert-success" role="alert">
            L'utilisateur a bien été ajouté !
          </div>
        )}
        <div className="users-container">
          {users.length !== 0 ? (
            <div style={{ width: "100%" }}>
              {showDeleteButton ? (
                <Button
                  sx={{ mb: 2 }}
                  onClick={() => {
                    console.log("goes here");
                    handleDeleteMultipleUsers();
                  }}
                >
                  Supprimer les utilisateurs sélectionnés
                </Button>
              ) : (
                <Button sx={{ mb: 2 }} disabled>
                  Veuillez sélectionner des utilisateurs
                </Button>
              )}

              <div style={{ height: "800px", width: "100%" }}>
                <DataGrid
                  className="table"
                  getRowHeight={() => "auto"}
                  checkboxSelection
                  //disableColumnFilter
                  onCellEditCommit={handleCellEditCommit}
                  //disableColumnSelector
                  disableRowSelectionOnClick
                  keepNonExistentRowsSelected
                  //disableDensitySelector
                  rows={rows_users}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows_users.filter((row) =>
                      selectedIDs.has(row.id.toString())
                    );
                    setShowDeleteButton(selectedIDs.size !== 0);
                    setSelectedRows(selectedRowData);
                  }}
                  columns={[
                    ...columns,
                    ...modules.map((module) => ({
                      field: module._id,
                      headerName: module.title,
                      editable: true,
                      flex: "∞",
                      width: 200,
                      valueGetter: ({ row }) => {
                        for (const userModule of row.modules) {
                          if (userModule.module === module._id) {
                            return (
                              secondsToMinutes(
                                userModule.timeSpentOnModule.toFixed(0)
                              ).toFixed(0) +
                              "/" +
                              userModule.time +
                              "min"
                            );
                          }
                        }
                      },
                    })),
                  ]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <h4 className="my-5">
              Vous n'avez aucun utilisateur, importez-en ou ajoutez-en
              manuellement
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminScreen;
