import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/LOGO-HYPOTENUSE2-removebg-preview.png";
import avion from "../assets/send.svg";
import user from "../assets/Icon feather-user.png";
import text from "../assets/INSTITUT HYPOTENUSEEcole Française de Massage.png";
import limited from "../assets/limited.svg";
import restantPhoto from "../assets/Restant.svg";
import ModuleCardMember from "./ModuleCardMember";
import cross from "../assets/cancel.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { baseUrl } from "../constants/baseUrl";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@mui/material/Avatar";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  addMonthsAndCalculateDifference,
  addMonthsToDate,
  addMonthsToDateAndReturnDate,
  convertToMonth,
  isDateAfter,
  minutesToHHMM,
  subtractSecondsAndFormat,
} from "../utils/convertDate";

const NavBar = () => {
  let navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));

  const [, , removeCookies] = useCookies(["token"]);

  const logoutHandler = () => {
    localStorage.removeItem("userInfoVideoPlateforme");
    removeCookies("token");
    navigate("/");
  };

  const [userInfos, setUserInfos] = useState(null);

  const [userModules, setUserModules] = useState([]);
  const [totalTimeAllocatedInModules, setTotalTimeAllocatedInModules] =
    useState(0);
  const [totalTimeSpentOnModules, setTotalTimeSpentOnModules] = useState(0);

  useEffect(() => {
    const getUserModules = async () => {
      const userInfo = JSON.parse(
        localStorage.getItem("userInfoVideoPlateforme")
      );
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserModules(data.modules.sort());
      for (let module of data.modules) {
        setTotalTimeSpentOnModules(
          (prevState) => prevState + module.timeSpentOnModule
        );
        setTotalTimeAllocatedInModules((prevState) => prevState + module.time);
      }
      console.log("total", totalTimeSpentOnModules);
    };
    getUserModules();
  }, []);

  const [adminMail, setAdminMail] = useState([]);

  useEffect(() => {
    const getUserInfos = async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("userInfoVideoPlateforme")
      );
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserInfos(data);
      console.log("data", data);
      const response = await axios.get(`${baseUrl}/api/mail`);
      setAdminMail(response.data);
    };
    getUserInfos();
  }, []);

  useEffect(() => {
    if (!userInfos || userInfos.role === "Admin") return;
    const timePass = 10;
    const endTime = addMonthsToDateAndReturnDate(
      userInfos.createdAt,
      userInfos.connectionTime
    );
    const shouldDisconnectUser = isDateAfter(new Date(), endTime);
    const checkTime = () => {
      if (shouldDisconnectUser) {
        return navigate("/", {
          state: {
            message:
              "Le temps de connexion disponible a été dépassé, veuillez contacter un administrateur. Code:1012",
          },
        });
      }
    };
    const timeout = setTimeout(() => {
      checkTime();
    }, timePass * 1000);
    checkTime();
    return () => clearTimeout(timeout);
  }, []);

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const sendEmail = async () => {
    await axios
      .post(`${baseUrl}/api/mail`, {
        to: adminMail[adminMail.length - 1].value,
        from: userInfos.email,
        html_output: `
            <html lang="fr">  
                <div>
                    <p>Vous avez une nouvelle demande de contact de ${userInfos.firstName} ${userInfos.lastName}<br>Son adresse mail est ${userInfos.email}</p>
                </div>
            </html>
        `,
        subject: "Vous avez une nouvelle demande de contact",
      })
      .then((data) => {
        toast.success("Un email a bien été envoyé a l'administrateur !");
      })
      .catch((data) => {
        toast.error("Impossible d'envoyer le mail");
      });
  };

  return (
    <>
      {userInfos && (
        <Navbar bg="white" expand="lg" className="big-nav container">
          <div className="navbar-left" onClick={() => navigate("/home")}>
            <img src={logo} alt="" />
            <img src={text} alt="" className="mt-4" />
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <>
            <Navbar.Collapse id="basic-navbar-nav" className="right-nav">
              <div className="nav-link-self d-flex justify-space-between member-area mt-4 member-container-nav">
                <img src={user} alt="" className="img-user" />
                <p
                  style={{ cursor: "pointer" }}
                  data-toggle="modal"
                  data-target="#navbarModalCenter"
                  className="member-para"
                >
                  Member area
                </p>
              </div>
              <div className="user-infos">
                <Nav.Link
                  href="#link"
                  className="nav-link-self img-notifications"
                >
                  <Avatar
                    sx={{ bgcolor: "#d32f2f" }}
                    style={{ fontWeight: "bold" }}
                  >
                    {capitalizeFirstLetter(userInfo?.firstName.charAt(0))}
                  </Avatar>
                </Nav.Link>
                {userInfo && (
                  <NavDropdown
                    title={
                      <div>
                        {" "}
                        {capitalizeFirstLetter(userInfo.firstName)}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          color="dark-grey"
                          className="chevron-right mt-3"
                        />
                      </div>
                    }
                    id="basic-nav-dropdown"
                    className="navbar-dropdown"
                  >
                    <NavDropdown.Item onClick={logoutHandler}>
                      Se déconnecter
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </div>
            </Navbar.Collapse>
          </>

          <div
            className="modal fade"
            id="navbarModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                {userInfos && (
                  <div className="dialog-member-container">
                    <div className="d-flex justify-content-between">
                      <h5 className="my-4">Member area</h5>
                      <img
                        src={cross}
                        alt=""
                        className="cross-img"
                        data-dismiss="modal"
                      />
                    </div>
                    <div className="restant-container">
                      <div className="card-restant">
                        <p className="delai-connection">
                          Temps alloué de connexion
                        </p>
                        <p className="duration">
                          {userInfos.connectionTime} mois
                        </p>
                        <div className="card-restant-title">
                          <div className="img-member-area-container">
                            <img
                              src={limited}
                              alt=""
                              className="img-member-area"
                            />
                          </div>
                          <div className="restant-text-container">
                            <h5 className="restant">Temps alloué restant</h5>
                            <p className="restant-text">
                              {addMonthsAndCalculateDifference(
                                userInfos.createdAt,
                                userInfos.connectionTime
                              )}{" "}
                              / {userInfos.connectionTime} mois
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card-restant">
                        <div>
                          <p className="delai-connection">
                            Temps alloué sur les modules
                          </p>

                          <p className="duration">
                            {minutesToHHMM(totalTimeAllocatedInModules)}
                          </p>
                        </div>
                        <div className="card-restant-title">
                          <div className="img-member-area-container">
                            <img
                              src={restantPhoto}
                              alt=""
                              className="img-member-area"
                            />
                          </div>
                          <div className="restant-text-container">
                            <h5 className="restant">Temps alloué restant</h5>
                            <p className="restant-text">
                              {subtractSecondsAndFormat(
                                totalTimeAllocatedInModules * 60,
                                totalTimeSpentOnModules
                              )}{" "}
                              / {minutesToHHMM(totalTimeAllocatedInModules)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="contact-admin" onClick={sendEmail}>
                      <p className="contact-admin-text">
                        <img
                          src={avion}
                          className="paper-plane-icon"
                          alt="paperplane"
                        />
                        Contacter l'administrateur
                      </p>
                    </div>

                    <h5 className="module-title-inner">Mes modules disponibles</h5>
                    <p className="module-span-inner">Note : Lorsque vous terminez un module, il disparaît de la liste ci-dessous.</p>
                    <div className="container">
                      <Row className="my-3">
                        {userModules.map((formation, index) => {
                          if (
                            userModules[index]?.time !== 0 &&
                            userModules[index]?.time * 60 -
                              userModules[index]?.timeSpentOnModule >
                              0
                          ) {
                            return (
                              <Col key={index} xs="12" sm="6">
                                <ModuleCardMember
                                  module={formation}
                                  time={userModules[index]}
                                />
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
