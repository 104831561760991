import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import SideBar from "../../components/SideBar";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { sendEmail } from "../../http/http_email";
import { toast } from "react-toastify";
import { updateProfile } from "../../http/http_user";

const DisconnectedScreen = () => {
  const [users, setUsers] = useState([]);
  const [adminMail, setAdminMail] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const { data } = await axios.get(`${baseUrl}/api/users/disconnected`);
      setUsers(data);
      const response = await axios.get(`${baseUrl}/api/mail`);
      setAdminMail(response.data);
    };
    getUsers();
  }, []);

  const formatDate = (date) => {
    if (date) {
      return `${new Date(date).toLocaleDateString("fr-FR")} à ${new Date(
        date
      ).getHours()} h`;
    } else {
      return "Aucun rapport envoyé";
    }
  };

  const columns = [
    { field: "firstName", headerName: "Prenom", width: 200 },
    { field: "lastName", headerName: "Nom", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "role", headerName: "Acces", width: 200 },
    {
      field: "disconnectedTime",
      headerName: "Date de dernière déconnection",
      width: 200,
    },
    {
      field: "numberOfDeconnections",
      headerName: "Nombre de déconnection(s)",
      width: 200,
    },
    {
      headerName: "Envoi rapport",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const email = params.row.email;
        const id = params.row.id;
        const lastDateEmail = params.row.lastDateEmail;
        const html_output = `
            <html lang="fr">  
                <div>
                    <p>Bonjour ${params.row.firstName},</p>
                    <p>Arrêtez de vous déconnecter !</p>
                </div>
            </html>
        `;
        const adminEmail = adminMail[adminMail.length - 1]?.value;
        const subject = "Tentative de déconnexion";
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={async () => {
                sendEmail(email, adminEmail, html_output, subject);
                updateProfile(id, new Date());
                const { data } = await axios.get(
                  `${baseUrl}/api/users/disconnected`
                );
                setUsers(data);
                toast.success("Votre email a bien été envoyé !", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }}
            >
              <EmailIcon />
            </Button>
            <div>{formatDate(lastDateEmail)}</div>
          </div>
        );
      },
    },
  ];

  const rows_users = users.map((user) => {
    return {
      id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      lastDateEmail: user.lastDateEmail,
      disconnectedTime: formatDate(user.disconnectedTime),
      numberOfDeconnections: user.numberOfDeconnections,
    };
  });

  return (
    <div className="d-flex">
      <SideBar />
      <div className="main-admin px-5">
        <h1 className="text-center my-5">Liste des utilisateurs déconnectés</h1>
        <div
          className="users-container"
          style={{ height: "800px", width: "100%" }}
        >
          {users.length !== 0 ? (
            <DataGrid
              className="table"
              getRowHeight={() => "auto"}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              rows={rows_users}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          ) : (
            <h4 className="my-5">Aucun utilisateur n'a été déconnecté</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisconnectedScreen;
