import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import { useNavigate } from "react-router-dom";

const ConfigurationAdmin = () => {
  const [adminMail, setAdminMail] = useState("");
  const [mailDone, setMailDone] = useState(false);
  const [mailAdmin, setMailAdmin] = useState([]);
  const [reloadTime, setReloadTime] = useState(5);
  const [reloadTimeDone, setReloadTimeDone] = useState(false);
  const [splitTime, setSplitTime] = useState(8);
  const [splitTimeDone, setSplitTimeDone] = useState(false);

  const navigate = useNavigate();
  const changeMail = async () => {
    await axios.post(`${baseUrl}/api/mail/define`, {
      mail: adminMail,
    });
    setMailDone(true);
    setMailAdmin([{ value: adminMail }]);
    setTimeout(() => setMailDone(false), 3000);
  };

  const changeReloadTime = async () => {
    await axios.post(`${baseUrl}/api/admin/config`, {
      type: "reload_time",
      value: reloadTime,
    });
    setReloadTimeDone(true);
    setTimeout(() => setReloadTimeDone(false), 3000);
  };

  const changeSplitTime = async () => {
    await axios.post(`${baseUrl}/api/admin/config`, {
      type: "split_time",
      value: splitTime,
    });
    setSplitTimeDone(true);
    setTimeout(() => setSplitTimeDone(false), 3000);
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoVideoPlateforme"));
    if (!userInfo) {
      navigate("/error");
    }
    const getMailAdmin = async () => {
      const { data } = await axios.get(`${baseUrl}/api/mail`);
      setMailAdmin(data);
    };
    const getReloadTime = async () => {
      const { data } = await axios.get(`${baseUrl}/api/admin/config/reload_time`);
      setReloadTime(Number(data?.value ?? "5"));
    };
    const getSplitTime = async () => {
      const { data } = await axios.get(`${baseUrl}/api/admin/config/split_time`);
      setSplitTime(Number(data?.value ?? "8"));
    };
    const getUser = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      if (data.role === "User") {
        navigate("/home");
      }
    };
    getUser();
    getMailAdmin();
    getReloadTime();
    getSplitTime();
  }, [navigate]);

  return (
    <div className="d-flex">
      <SideBar />

      <div className="container main-admin">
        <h3 className="mr-5 text-center">Adresse mail de l'admin</h3>
        <h5>L'adresse actuelle est : {mailAdmin?.length > 0 ? mailAdmin[mailAdmin.length - 1].value : ""}</h5>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Changer l'adresse mail de l'admin</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setAdminMail(e.target.value)}
          />
        </div>
        <div className="btn button-bootstrap" onClick={changeMail}>
          Changer l'adresse mail
        </div>

        {mailDone && (
          <div className="alert alert-success my-5" role="alert">
            L'adresse mail de l'admistrateur a bien été changée !
          </div>
        )}
        <hr />
        <h3 className="mr-5 text-center">Temps d'actualisation des scripts</h3>
        <div className="form-group">
          <label htmlFor="exampleInputTime1">Changer le temps d'actualisation (secondes)</label>
          <input
            type="number"
            value={reloadTime}
            className="form-control"
            id="exampleInputTime1"
            aria-describedby="timeHelp"
            onChange={(e) => setReloadTime(e.target.value)}
          />
        </div>
        <div className="btn button-bootstrap" onClick={changeReloadTime}>
          Changer le temps d'actualisation
        </div>

        {reloadTimeDone && (
          <div className="alert alert-success my-5" role="alert">
            Le temps d'actualisation a bien été changée !
          </div>
        )}
        <hr />
        <h3 className="mr-5 text-center">Durée des découpes de vidéos</h3>
        <div className="form-group">
          <label htmlFor="exampleInputTime1">Changer la durée des découpes de vidéos (secondes)</label>
          <input
            type="number"
            value={splitTime}
            className="form-control"
            id="exampleInputTime1"
            aria-describedby="timeHelp"
            onChange={(e) => setSplitTime(e.target.value)}
          />
        </div>
        <div className="btn button-bootstrap" onClick={changeSplitTime}>
          Changer la durée des découpes de vidéos
        </div>

        {splitTimeDone && (
          <div className="alert alert-success my-5" role="alert">
            La durée des découpes de vidéos a bien été changée !
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigurationAdmin;
