import React, { useCallback, useContext, useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import FormationCard from "../components/FormationCard";
import axios from "axios";
import { Grid } from "@mui/material";
import FormationLockedCard from "../components/FormationLockedCard";
import { baseUrl } from "../constants/baseUrl";
import OpenContext from "../context/open-context";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const HomeScreen = () => {
  const [modules, setModules] = useState([]);
  const [userModules, setUserModules] = useState([]);
  const [reloadTime, setReloadTime] = useState(5);

  const [profile, setProfile] = useState(null);

  const [, , removeCookies] = useCookies(["token"]);

  const navigate = useNavigate();
  const ctx = useContext(OpenContext);

  //PPM TEMP Const for DEV MODE
  //const logoutHandler = (async () => {});
  const logoutHandler = useCallback(async () => {
    //PPM Info: Function for disconnect user if they made unauthorized action
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    await axios.put(`${baseUrl}/api/users/profile`, {
      _id: userInfo._id,
      disconnected: true,
      disconnectedTime: new Date(),
    });
    await axios.put(`${baseUrl}/api/users/deconnections`, {
      _id: userInfo._id,
    });
    localStorage.removeItem("userInfoVideoPlateforme");
    removeCookies("token");
    navigate("/", {
      state: {
        message:
          "vous avez commis une action non autorisée, veuillez vous reconnecter",
      },
    }).then(() => window.location.reload());
  }, [navigate, removeCookies]);

  const findUserModule = useCallback(
    (module_id) => {
      for (let i = 0; i < userModules.length; i++)
        if (userModules[i].module === module_id) return userModules[i];
      return null;
    },
    [userModules]
  );

  useEffect(() => {
    const onKeyDown = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    };
    const onContextMenu = (e) => {
      logoutHandler();
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("contextmenu", onContextMenu);

    const interval = setInterval(() => {
      console.log(
        Object.defineProperties(new Error(), {
          message: {
            get() {
              logoutHandler();
            },
          },
          toString: {
            value() {
              new Error().stack.includes("toString@") && alert("Safari");
            },
          },
        })
      );
    }, 1000);

    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    if (userInfo) {
      axios
        .post(`${baseUrl}/api/users/profile`, {
          _id: userInfo._id,
        })
        .then(({ data }) => setProfile({ ...data, _id: userInfo._id }));
    }

    const getReloadTime = async () => {
      const { data } = await axios.get(
        `${baseUrl}/api/admin/config/reload_time`
      );
      setReloadTime(Number(data?.value ?? "5"));
    };

    getReloadTime();

    return () => {
      clearInterval(interval);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("contextmenu", onContextMenu);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (profile?.role === "User") {
        const timePassedOnWebsite =
          profile.totalTimeSpentOnWebsite + reloadTime;
        await axios.put(`${baseUrl}/api/users/profile`, {
          _id: profile._id,
          timeSpentOnWebsite: timePassedOnWebsite,
        });
        setProfile((profile) => ({
          ...profile,
          totalTimeSpentOnWebsite: timePassedOnWebsite,
        }));
      }
    }, reloadTime * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [profile, reloadTime]);

  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    if (!userInfo) {
      navigate("/error");
    }
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`, config);
      setModules(data);
    };
    const getUserModules = async () => {
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserModules(data.modules);
    };
    getModules();
    getUserModules();
  }, [navigate, ctx]);

  return (
    <div>
      <NavBar />
      <div className="main pt-3">
        <div className="container">
          <h1 className="head-title mb-3">Nos formations</h1>

          {modules && userModules ? (
            <div className="list-formations-container">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
              >
                {modules.map((formation, index) => {
                  const userModule = findUserModule(formation._id);
                  if (
                    userModule?.time === 0 ||
                    userModule?.time * 60 - userModule?.timeSpentOnModule <= 0
                  ) {
                    return (
                      <Grid item xs={2} sm={4} md={4} key={index}>
                        <FormationLockedCard formation={formation} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={2} sm={4} md={4} key={index}>
                        <FormationCard formation={formation} />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
