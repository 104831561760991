import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "../constants/baseUrl";
import squares from "../assets/multi-square.svg";
import VideoCard from "../components/VideoCard";
import NavBar from "../components/NavBar";
import ModuleTimeChecker from "../components/ModuleTimeChecker";
import { useCookies } from "react-cookie";

const DocumentScreen = () => {
  const [videos, setVideos] = useState([]);

  const [, , removeCookies] = useCookies(["token"]);

  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  //PPM TEMP Const for DEV MODE
  //const logoutHandler = {};
  const logoutHandler = useCallback(async () => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );

    await axios.put(`${baseUrl}/api/users/profile`, {
      _id: userInfo._id,
      disconnected: true,
      disconnectedTime: new Date(),
    });

    localStorage.removeItem("userInfoVideoPlateforme");
    removeCookies("token");

    navigate("/", {
      state: {
        message:
          "vous avez commis une action non autorisée, veuillez vous reconnecter",
      },
    });
  }, [navigate, removeCookies]);

  useEffect(() => {
    const onKeyDown = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    };
    const onContextMenu = (e) => {
      logoutHandler();
    };

    const interval = setInterval(() => {
      console.log(
        Object.defineProperties(new Error(), {
          message: {
            get() {
              logoutHandler();
            },
          },
          toString: {
            value() {
              new Error().stack.includes("toString@") && alert("Safari");
            },
          },
        })
      );
    }, 1000);

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("contextmenu", onContextMenu);

    return () => {
      clearInterval(interval);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("contextmenu", onContextMenu);
    };
  }, [logoutHandler]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/upload/${params.id}`)
      .then(({ data }) => setVideos(data));
  }, [navigate, params?.id]);

  return (
    <div>
      <NavBar />
      {videos && (
        <div className="main pt-3">
          <div className="document-container container">
            <div className="debuter">
              <div className="d-flex debuter-container">
                <img src={squares} alt="" className="squares-img" />
                <p className="white">Pour débuter en tant que pro !</p>
              </div>
              <div
                className="see-all-modules-container"
                onClick={() => navigate("/home")}
              >
                <p className="white see-all-modules">Voir tous les modules</p>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  color="white"
                  className="arrow-right"
                />
              </div>
            </div>
            <ModuleTimeChecker />

            <div className="video-card-container header-document">
              <h1 className="head-title mb-3">
                Documents & vidéos du module {state?.formation.title}
              </h1>
              <h5 className="min-text">MIN</h5>
              <h5 className="min-text">FORMAT</h5>
            </div>

            {videos.length !== 0 ? (
              <>
                {videos.map((video, index) => {
                  return <VideoCard video={video} key={index} />;
                })}
              </>
            ) : (
              <h5>Ce module ne contient pas de média pour le moment</h5>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentScreen;
