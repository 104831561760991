import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import NavBarError from "../components/NavBarError";

const ErrorScreen = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfoVideoPlateforme'));

    return (
        <div>
            <NavBarError/>
            <div className='container'>

                <h5>Vous êtes perdus ?</h5>
                {userInfo ?
                    <p>Retour <Link to="/home">a la page d'accueil</Link></p>
                    :
                    <p>Pour <Link to="/">vous connecter</Link></p>
                }

            </div>
        </div>
    );

};

export default ErrorScreen;
