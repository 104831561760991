import React from 'react';
import { Navbar } from "react-bootstrap";
import logo from "../assets/LOGO-HYPOTENUSE2-removebg-preview.png";
import text from "../assets/INSTITUT HYPOTENUSEEcole Française de Massage.png";

const NavBarError = () => {
    return (
        <Navbar bg="white" expand="lg" className="big-nav container">
            <div className="navbar-left">
                <img src={logo} alt=""/>
                <img src={text} alt="" className="mt-4"/>
            </div>
        </Navbar>
    );
};

export default NavBarError;
