import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import SideBar from "../../components/SideBar";
import { Table } from "react-bootstrap";
import ChangedData from "../../components/ChangedData";
import { baseUrl } from "../../constants/baseUrl";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ImportUsersScreen = () => {
  const navigate = useNavigate();

  const [excelItems, setExcelItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [changedData, setChangedData] = useState([]);

  const [modules, setModules] = useState([]);
  const [finalLoading, setFinalLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [userInfos, setUserInfos] = useState([]);

  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    if (!userInfo) {
      navigate("/error");
    }
    const getModules = async () => {
      const { data } = await axios.get(`${baseUrl}/api/modules`);
      setModules(data);
    };
    const getUser = async () => {
      const userInfo = JSON.parse(
        localStorage.getItem("userInfoVideoPlateforme")
      );
      const { data } = await axios.post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      });
      setUserInfos(data);
      if (data.role === "User") {
        navigate("/home");
      }
    };
    getUser();
    getModules();
  }, []);

  const readExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file[0]);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { range: 1 });

        //PPM I transform to Upperletter the first letter (for fix a bug)
        const transformedData = data.map((item) => {
          if (item.Acces) {
            item.Acces = item.Acces.charAt(0).toUpperCase() + item.Acces.slice(1);
          }
          return item;
        });

        resolve(transformedData);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((data) => {
      setExcelItems(data);
    });
  };

  const onSubmit = async (e) => {
    let file = e.target.files;
    e.preventDefault();
    setIsLoading(true);
    await readExcel(file);
    setIsLoading(false);
  };

  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false);
  const [askedDB, setAskedDB] = useState(false);
  const [populateDone, setPopulateDone] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  // Verify if the module inside the excel actually exist
  const [verifiedModules, setVerifiedModules] = useState(false);
  const [verifiedTimeOfConnection, setVerifiedTimeOfConnection] = useState("");
  const [verifiedLengthOfModule, setVerifiedLengthOfModule] = useState(false);
  const checkChangeBeforeSendingToDB = async () => {
    setAskedDB(true);
    setIsLoading(true);
    for (let i = 0; i < excelItems.length; i++) {
      try {
        const response = await axios.post(`${baseUrl}/api/admin/check`, {
          data: excelItems[i],
        });
        // console.log(response.data);
        console.log(response.status);
        if (response.status === 201) {
          setChangedData((prevState) => [...prevState, response.data]);
          setChangesHaveBeenMade(true);
        }
        if (response.status === 202) {
          setChangedData((prevState) => [...prevState, response.data]);
          setVerifiedModules(true);
        }
        if (response.status === 203) {
          setChangedData((prevState) => [...prevState, response.data]);
          setVerifiedTimeOfConnection(response.data.message);
        }
        if (response.status === 204) {
          setChangedData((prevState) => [...prevState, response.data]);
          setVerifiedLengthOfModule(true);
        }
      } catch (err) {
        toast.error("Une erreur a été détectée, elle a été ignorée");
        setIsLoading(false);
        console.log(err);
      }
    }
    setPopulateDone(true);
    setIsLoading(false);
  };

  const sendToDB = async () => {
    setFinalLoading(true);
    for (let data of changedData) {
      if (userInfos.role !== "Admin" && data.Acces !== "User") {
        setUnauthorized(true);
        setFinalLoading(false);
      } else {
        try {
          await axios.post(`${baseUrl}/api/admin`, {
            data: data,
          });
        } catch (err) {
          setFinalLoading(false);
        }
      }
    }
    setAskedDB(false);
    setFinalLoading(false);
    setDone(true);
    setTimeout(() => {
      setDone(false);
    }, 5000);
  };

  const sortedModules = [...modules];
  sortedModules.sort((a, b) => {
    const idA = parseInt(a._id, 16);
    const idB = parseInt(b._id, 16);
    return idA - idB;
  });

  return (
    <div className="d-flex">
      <SideBar />
      <div className="main-admin">
        <h1 className="text-center my-5">Importez des utilisateurs</h1>
        <div className="import-user-container d-flex justify-content-between">
          <input type="file" onChange={onSubmit} />
          {isLoading && <Spinner animation="border" />}
          <button
            onClick={checkChangeBeforeSendingToDB}
            className="btn button-bootstrap"
          >
            Soumettre
          </button>
        </div>

        {changedData.length === 0 && populateDone && (
          <div className="alert alert-danger my-5" role="alert">
            Aucun changement n'a été effectué
          </div>
        )}

        {askedDB && modules && (
          <>
            {changesHaveBeenMade && !isLoading ? (
              <div className="container">
                <h5 className="my-3 text-center">
                  Voici les changements que vous vous apprêtez à effectuer :
                </h5>
                <Table responsive className="table-sm table-users">
                  <thead>
                    <tr>
                      <th>Prénom</th>
                      <th>Nom</th>
                      <th>Temps de connexion</th>
                      <th>Email</th>
                      <th>Acces</th>
                      <th>Session</th>
                      {sortedModules.map((module, index) => {
                        return <th key={index}>{module.title}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {changedData.map((data, index) => {
                      return <ChangedData user={data} />;
                    })}
                  </tbody>
                </Table>

                {finalLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <h5>Souhaitez-vous poursuivre ?</h5>
                    <div className="d-flex">
                      <button
                        className="mx-5 btn button-bootstrap"
                        style={{ cursor: "pointer" }}
                        onClick={sendToDB}
                      >
                        Oui
                      </button>
                      <button
                        style={{ cursor: "pointer" }}
                        className="btn btn-danger"
                        onClick={() => {
                          setAskedDB(false);
                          setChangedData([]);
                        }}
                      >
                        Non
                      </button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <p></p>
            )}
          </>
        )}

        {verifiedModules && (
          <div className="alert alert-danger" role="alert">
            Certains de vos modules n'ont pas été créés
          </div>
        )}
        {unauthorized && (
          <div className="alert alert-danger" role="alert">
            Un ou plusieurs utilisateurs ont le status Admin ou Manager. Vous ne
            disposez pas des droits pour les ajouter. Ils n'ont donc pas été
            pris en compte !
          </div>
        )}

        {verifiedTimeOfConnection && (
          <div className="alert alert-danger" role="alert">
            {verifiedTimeOfConnection}
          </div>
        )}

        {verifiedLengthOfModule && (
          <div className="alert alert-danger" role="alert">
            Il vous manque des modules
          </div>
        )}

        {done && (
          <div className="alert alert-success my-5" role="alert">
            Vos changements ont bien été effectués !
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportUsersScreen;
