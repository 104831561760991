import React from "react";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import { useNavigate } from "react-router-dom";

const CardModuleAdmin = ({ module }) => {
  const deleteModule = async () => {
    await axios.delete(`${baseUrl}/api/modules/${module._id}`);
    await axios.delete(`${baseUrl}/api/users/modules/${module._id}`);
    window.location.reload();
  };

  const navigate = useNavigate();

  return (
    <div className="d-flex border border-dark py-3 px-3 my-3 card-module rounded">
      <img
        src={`${baseUrl}/api/modules/${module._id}/image`}
        alt="module"
        height={200}
        width={200}
        className="module-admin-img mx-5"
      />
      <div className="description-module-container">
        <p>
          <span className="module-span">Id :</span> {module._id}
        </p>
        <p>
          <span className="module-span">Titre :</span> {module.title}
        </p>
        <p>
          <span className="module-span">Description :</span> {module.description}
        </p>
      </div>

      <div className="mx-5 w-25">
        <button
          onClick={() =>
            navigate("/modify-module", {
              state: {
                module,
              },
            })
          }
          className="btn btn-warning "
        >
          Modifier
        </button>
        <button onClick={deleteModule} className="btn btn-danger mx-5">
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default CardModuleAdmin;
