import React from "react";
import { Card } from "react-bootstrap";

import lock from "../assets/Icon awesome-lock.png";
import { baseUrl } from "../constants/baseUrl";

const FormationLockedCard = ({ formation }) => {
  return (
    <Card className="card-formation card-locked">
      <div className="">
        <Card.Img
          variant="top"
          src={`${baseUrl}/api/modules/${formation._id}/image`}
          className="img-background card-home-img"
        />
      </div>
      <img src={lock} alt="" className="img-lock" />
      <Card.Body className="card-body-lock">
        <Card.Title>{formation.title}</Card.Title>
        <Card.Text>{formation.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default FormationLockedCard;
