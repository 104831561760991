import React from 'react';
import logo from '../assets/LOGO-HYPOTENUSE2.svg';
import {useNavigate} from "react-router-dom";

const InterdictionScreen = () => {

    const navigate = useNavigate()

    return (
        <div className='container py-5'>
            <img src={logo} alt=""/>

            <div className='my-5'>
                <h5>Vous n'avez plus accès au site.</h5>
                <h5>Contactez l'administrateur</h5>
            </div>


            <div className="forbidden-button" onClick={() => navigate('/')}>
                <p>Connexion</p>
            </div>

            <div className="copyright copyright-forbidden">
                <p style={{marginBottom: "0"}}>© 2022-2024 Ecole Française de Massage</p>
                <p>Tous les droits sont réservés</p>
            </div>

        </div>
    );
};

export default InterdictionScreen;
