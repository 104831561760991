import { Card, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
    isFaceDetectionModelLoaded,
    isFacialLandmarkDetectionModelLoaded,
    isFeatureExtractionModelLoaded,
    loadModels,
} from "../utils/faceUtil";
import { DEFAULT_UPLOAD_OPTION, UPLOAD_OPTION } from "../globalData";
import ModelLoadStatus from "../utils/ModelLoadStatus";
import ModelLoading from "../utils/ModelLoading";
import { UploadFromWebcam } from "../components/WebcamUpload";

const { Option } = Select;

export default ({ galleryRefetch, countRefetch }) => {

    const [isAllModelLoaded, setIsAllModelLoaded] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [loadingMessageError, setLoadingMessageError] = useState("");

    useEffect(() => {
        async function loadingtheModel() {
            await loadModels(setLoadingMessage, setLoadingMessageError);
            setIsAllModelLoaded(true);
        }
        if (
            !!isFaceDetectionModelLoaded() &&
            !!isFacialLandmarkDetectionModelLoaded() &&
            !!isFeatureExtractionModelLoaded()) {
            setIsAllModelLoaded(true);
            return;
        }

        loadingtheModel();
    }, [isAllModelLoaded]);

    return (
        <Card>
            <Card title="Model Load">
                <ModelLoadStatus errorMessage={loadingMessageError} />
            </Card>
            <br />
            {!isAllModelLoaded ? (
                <ModelLoading loadingMessage={loadingMessage} />
            ) : loadingMessageError ? (
                <div className="error">{loadingMessageError}</div>
            ) : (
                isAllModelLoaded &&
                loadingMessageError.length === 0 && (
                    <div>
                            <UploadFromWebcam
                                galleryRefetch={galleryRefetch}
                                countRefetch={countRefetch}
                            />
                    </div>))}
        </Card>
    );
};
