import axios from "axios";
import { baseUrl } from "../constants/baseUrl";

export const sendEmail = async (to, from, html_output, subject) => {
  await axios.post(`${baseUrl}/api/mail`, {
    to,
    from,
    html_output,
    subject,
  });
};
