import React from "react";
import { useNavigate } from "react-router";

import { baseUrl } from "../constants/baseUrl";

const ModuleCardMember = ({ module, time }) => {
  const navigate = useNavigate();

  return (
    <>
      {time && (
        <div
          className="module-card-member my-3"
          data-dismiss="modal"
          onClick={() => {
            navigate(`/module/${module.module}`, {
              state: {
                formation: module,
              },
            });
          }}
        >
          <h5>{module.title}</h5>
          <p className="module-title">{module.description}</p>
          <div className="d-flex justify-content-between">
            <div className="img-module-container">
              <img
                src={`${baseUrl}/api/modules/${module.module}/image`}
                alt=""
                className="popup-img"
              />
            </div>
            <div className="temps-restant-container">
              <h5 className="temps-restant">Temps restant / Temps alloué</h5>
              <p className="restant-per-module">
                {(time.time - time.timeSpentOnModule / 60).toFixed(0)} min /{" "}
                {time.time} min
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModuleCardMember;
