import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../constants/baseUrl";

const ModuleTimeChecker = () => {
  const [restant, setRestant] = useState(0);
  const [profile, setProfile] = useState(null);
  const [module, setModule] = useState(null);
  const [reloadTime, setReloadTime] = useState(5);

  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoVideoPlateforme")
    );
    if (!userInfo || state === null) {
      navigate("/error");
    }
    axios
      .post(`${baseUrl}/api/users/profile`, {
        _id: userInfo._id,
      })
      .then(({ data }) => {
        setProfile({ ...data, _id: userInfo._id });
        const module = data.modules.filter((obj) => {
          return obj.module === params.id;
        })[0];
        setModule(module);
        setRestant(module.time * 60 - module.timeSpentOnModule);
      });
  }, [navigate, state, params?.id]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!module) return;

      const timeSpentOnModule = module.timeSpentOnModule + reloadTime;
      if (profile.role === "User") {
        await axios.put(`${baseUrl}/api/users/modules`, {
          _id: profile._id,
          moduleID: module.module,
          timeSpentOnModule: timeSpentOnModule,
        });
        const totalTime = profile.totalTimeSpentOnWebsite + reloadTime;
        await axios.put(`${baseUrl}/api/users/profile`, {
          _id: profile._id,
          timeSpentOnWebsite: totalTime,
        });
        setRestant(module.time * 60 - timeSpentOnModule);
        setModule((module) => ({
          ...module,
          timeSpentOnModule: timeSpentOnModule,
        }));
        setProfile((profile) => ({
          ...profile,
          totalTimeSpentOnWebsite: totalTime,
        }));
      }
    }, reloadTime * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate, params.id, module, profile, reloadTime]);

  useEffect(() => {
    if (
      profile?.role === "User" &&
      module &&
      module.time * 60 <= module.timeSpentOnModule
    ) {
      navigate("/home");
    }
  }, [navigate, module, profile?.role]);

  useEffect(() => {
    const getReloadTime = async () => {
      const { data } = await axios.get(
        `${baseUrl}/api/admin/config/reload_time`
      );
      setReloadTime(Number(data?.value ?? "5"));
    };
    getReloadTime();
  }, []);

  return (
    restant !== 0 &&
    module && (
      <h5 className="mb-5">
        <span class="text-danger">
          Attention le décompte commence maintenant
        </span>
        , <Link to="/home">sortez du module</Link> pour le stopper. Temps
        restant sur ce module {Math.ceil(restant / 60)} / {module.time}{" "}
        minute(s){" "}
      </h5>
    )
  );
};

export default ModuleTimeChecker;
