import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../constants/baseUrl";
import { useNavigate } from "react-router-dom";
import { Viewer } from "@react-pdf-viewer/core";
import ReactHlsPlayer from "react-hls-player/dist";
import { useCookies } from "react-cookie";
import pdf_file from "../../assets/pdf_file.png";

const InnerVideoCardAdmin = ({ video, module }) => {
  const [showFile, setShowFile] = useState(false);

  const deleteVideo = async () => {
    await axios.delete(`${baseUrl}/api/upload/${video._id}`);
    window.location.reload();
  };

  const isIphone = useMemo(() => /iphone/i.test(navigator.userAgent), []);

  const [cookies] = useCookies(["token"]);
  const navigate = useNavigate();

  return (
    <div className="d-flex border border-dark py-5 px-3 my-3 card-module rounded">
      {video.type === "pdf" ? (
        <>
          {!showFile ? (
            <img src={pdf_file} className="w-10 mx-5" alt="preview" onClick={() => setShowFile(true)} />
          ) : (
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "400px",
                width: "400px",
              }}
            >
              <Viewer
                fileUrl={`${baseUrl}/api/upload/${video._id}/pdf`}
                httpHeaders={{ Authorization: cookies.token }}
                renderError={(error) => <div>Impossible de lire le PDF</div>}
              />
            </div>
          )}
        </>
      ) : !showFile ? (
        <img
          src={`${baseUrl}/api/upload/${video._id}/image`}
          className="w-25 mx-5"
          alt="preview"
          onClick={() => setShowFile(true)}
        />
      ) : isIphone ? (
        <video width="100%" height="auto" controls autoPlay={false} controlsList="nodownload">
          <source src={`${baseUrl}/api/upload/hls/${video._id}/output.m3u8`} />
        </video>
      ) : (
        <ReactHlsPlayer
          src={`${baseUrl}/api/upload/hls/${video._id}/output.m3u8`}
          autoPlay={false}
          controls={true}
          className="module-admin-img mx-5 w-25"
          height="auto"
          hlsConfig={{
            xhrSetup: function (xhr, url) {
              xhr.setRequestHeader("Authorization", cookies.token);
            },
          }}
          onError={(event) => console.error(event.target.error.message)}
        />
      )}
      <div className="description-module-container">
        <p>
          <span className="module-span">Titre :</span> {video.title}
        </p>
        <p>
          <span className="module-span">Description :</span> {video.description}
        </p>
      </div>

      <div>
        <button
          onClick={() => {
            navigate("/modify-video", {
              state: {
                video,
                module,
              },
            });
          }}
          className="btn btn-warning"
        >
          Modifier
        </button>
        <button onClick={deleteVideo} className="btn btn-danger mx-5">
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default InnerVideoCardAdmin;
