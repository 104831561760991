import React from "react";

const ChangedData = ({ user }) => {
  const array = Object.entries(user);

  array.splice(0, 6);
  array.sort((a, b) => a[0].localeCompare(b[0]));
  return (
    <tr key={user._id} className="table-tr">
      <td>{user.Prenom}</td>
      <td>{user.Nom}</td>
      <td>{user.Times_of_connection}</td>
      <td>
        <a href={`mailto:${user.email}`}>{user.Email}</a>
      </td>
      <td>{user.Acces}</td>
      <td>{user.Session}</td>
      {array.sort().map((arr, index) => {
        return <td key={index}>{arr[1]}</td>;
      })}
    </tr>
  );
};

export default ChangedData;
