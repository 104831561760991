import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/HomeScreen.css";
import "./styles/NavBar.css";
import "./styles/AdminScreen.css";
import "./styles/SideBar.css";
import "./styles/ImageUpload.css";
import "./styles/VideoUpload.css";
import "./styles/AddModuleScreen.css";
import "./styles/LoginScreen.css";
import "./styles/DocumentScreen.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import DocumentScreen from "./screens/DocumentScreen";
import LoginScreen from "./screens/LoginScreen";
import AdminScreen from "./screens/admin/AdminScreen";
import ImportUsersScreen from "./screens/admin/ImportUsersScreen";
import AddModuleScreen from "./screens/admin/AddModuleScreen";
import AddVideoScreen from "./screens/admin/AddVideoScreen";
import FaceRecognitionScreen from "./screens/FaceRecognitionScreen";
import ModifierModuleScreen from "./screens/admin/ModifierModuleScreen";
import ModifierVideoScreen from "./screens/admin/ModifierVideoScreen";
import OpenContext from "./context/open-context";
import InterdictionScreen from "./screens/InterdictionScreen";
import ConfigurationAdmin from "./screens/admin/ConfigurationAdmin";
import { Worker } from "@react-pdf-viewer/core";
import ErrorScreen from "./screens/ErrorScreen";
import DisconnectedScreen from "./screens/admin/DisconnectedScreen";
import { ToastContainer } from "react-toastify";

function App() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [timeSpentOnWebsite, setTimeSpentOnWebsite] = useState(0);
  const [maxTime, setMaxTime] = useState(0);

  const [time, setTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <OpenContext.Provider
        value={{
          open: open,
          totalTimeSpentOnWebsite: timeSpentOnWebsite,
          handleClose: handleClose,
          handleClickOpen: handleClickOpen,
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
          <Router>
            <Routes>
              <Route exact path="/" element={<LoginScreen />} />
              <Route exact path="/home" element={<HomeScreen />} />
              <Route exact path="/module/:id" element={<DocumentScreen />} />
              <Route exact path="/admin" element={<AdminScreen />} />
              <Route
                exact
                path="/import-users"
                element={<ImportUsersScreen />}
              />
              <Route
                exact
                path="/admin/disconnected"
                element={<DisconnectedScreen />}
              />
              <Route exact path="/add-module" element={<AddModuleScreen />} />
              <Route exact path="/add-video" element={<AddVideoScreen />} />
              <Route exact path="/forbidden" element={<InterdictionScreen />} />
              <Route
                exact
                path="/face-recognition"
                element={<FaceRecognitionScreen />}
              />
              <Route
                exact
                path="/modify-module"
                element={<ModifierModuleScreen />}
              />
              <Route
                exact
                path="/modify-video"
                element={<ModifierVideoScreen />}
              />
              <Route
                exact
                path="/configuration"
                element={<ConfigurationAdmin />}
              />
              <Route exact path="/error" element={<ErrorScreen />} />
              <Route path="*" element={<ErrorScreen />} />
            </Routes>
          </Router>
        </Worker>
      </OpenContext.Provider>
    </React.Fragment>
  );
}

export default App;
