import React, { useCallback, useEffect, useState } from "react";
import logo from "../assets/LOGO-HYPOTENUSE2.svg";
import faceID from "../assets/Face ID.png";
import shape1 from "../assets/shape 1.png";
import shape2 from "../assets/shape2.png";
import emailIcon from "../assets/email.svg";
import { useLocation } from "react-router-dom";
import { faWarning } from "@fortawesome/free-solid-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Dialog } from "@mui/material";
import {
  isFaceDetectionModelLoaded,
  isFacialLandmarkDetectionModelLoaded,
  isFeatureExtractionModelLoaded,
  loadModels,
} from "../utils/faceUtil";
import ModelLoading from "../utils/ModelLoading";
import { UploadFromWebcam } from "../components/WebcamUpload";
import { WebcamLogin } from "../components/WebcamLogin";
import { baseUrl } from "../constants/baseUrl";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

function SimpleDialog(props) {
  const { onClose, open, email, userInfos } = props;

  const handleClose = () => {
    onClose();
  };

  const [isAllModelLoaded, setIsAllModelLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingMessageError, setLoadingMessageError] = useState("");

  useEffect(() => {
    async function loadingtheModel() {
      await loadModels(setLoadingMessage, setLoadingMessageError);
      setIsAllModelLoaded(true);
    }
    if (
      !!isFaceDetectionModelLoaded() &&
      !!isFacialLandmarkDetectionModelLoaded() &&
      !!isFeatureExtractionModelLoaded()
    ) {
      setIsAllModelLoaded(true);
      return;
    }

    loadingtheModel();
  }, [isAllModelLoaded]);

  return (
    <Dialog onClose={handleClose} open={open}>
      {!isAllModelLoaded ? (
        <ModelLoading loadingMessage={loadingMessage} />
      ) : loadingMessageError ? (
        <div className="error">{loadingMessageError}</div>
      ) : (
        isAllModelLoaded &&
        loadingMessageError.length === 0 && (
          <div className="mat-dialog-content">
            {userInfos && userInfos.firstConnexion === false ? (
              <WebcamLogin userDetails={userInfos} email={email} />
            ) : (
              <UploadFromWebcam email={email} userInfo={userInfos} />
            )}
          </div>
        )
      )}
    </Dialog>
  );
}

const LoginScreen = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [, , removeCookies] = useCookies(["token"]);

  const [open, setOpen] = React.useState(false);
  const [userInfos, setUserInfos] = useState(null);
  const [askPassword, setAskPassword] = useState(false);
  const { state } = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    localStorage.removeItem("userInfoVideoPlateforme");
    removeCookies("token");
    setMessage(state?.message);
  }, [state?.message, removeCookies]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAuth = useCallback(async () => {
    try {
      const { data } = await axios.post(`${baseUrl}/api/users/login`, {
        email: email,
      });
      setUserInfos(data);
      if (data.password) {
        setAskPassword(true);
      } else {
        setOpen(true);
      }
    } catch (err) {
      setError("Cet email n'existe pas");
    }
  }, [email]);

  const [cookies, setCookies] = useCookies(["token"]);

  const handleAuthWithPassword = async () => {
    try {
      const { data } = await axios.post(`${baseUrl}/api/users/loginpassword`, {
        email: email,
        password,
      });
      setUserInfos(data);
      localStorage.setItem("userInfoVideoPlateforme", JSON.stringify(data));
      if (!cookies.token) {
        axios.defaults.headers.common["Authorization"] = data.token;
        setCookies("token", data.token, { path: "/" });
        localStorage.setItem("token", data.token);
        navigate("/home");
      }
    } catch (err) {
      setError("Votre mot de passe est incorrect");
    }
  };

  return (
    <div className="login-container">
      <div className="enter-email">
        <img src={logo} alt="" className="img-logo" />
        <p className="connection-text">
          Connectez-vous à votre compte ci-dessous
        </p>
        <div className="input-container">
          <img src={emailIcon} className="email-icon" alt="Email icon" />
          <input
            type="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            value={email}
            autoFocus
            className="email-input"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {askPassword && (
          <div className="input-container mt-3">
            <input
              type="password"
              placeholder="Mot de passe"
              name="password"
              value={password}
              autoFocus
              className="email-input"
              onChange={(e) => {
                console.log("e", e.target.value);
                setPassword(e.target.value);
              }}
            />
          </div>
        )}
        {error && (
          <p className="error">
            {" "}
            <FontAwesomeIcon icon={faWarning} className="warning-icon" />
            {error}
          </p>
        )}
        {message && (
          <p className="error">
            {" "}
            <FontAwesomeIcon icon={faWarning} className="warning-icon" />
            {message}
          </p>
        )}
        {askPassword ? (
          <div
            className="suivant-button"
            onClick={async () => {
              await handleAuthWithPassword();
            }}
          >
            <p>Se connecter</p>
          </div>
        ) : (
          <div
            className="suivant-button"
            onClick={async () => {
              await handleAuth();
            }}
          >
            <p>Suivant</p>
          </div>
        )}

        <div className="copyright">
          <p style={{ marginBottom: "0" }}>© 2022-{currentYear} Ecole Française de Massage</p>
          <p>Tous les droits sont réservés</p>
        </div>
      </div>

      <SimpleDialog
        open={open}
        email={email}
        userInfos={userInfos}
        onClose={handleClose}
      />

      <div className="faceId">
        <img src={shape1} alt="" className="shape1" />
        <div className="face-id-container">
          <img src={faceID} alt="" />
        </div>
        <h5 className="id-title">Reconnaissance Faciale</h5>
        <p className="id-title">
          Bienvenue sur la plateforme numérique de formation de l'Institut
          Hypoténuse !
        </p>
        <img src={shape2} alt="" className="shape2" />
      </div>
    </div>
  );
};

export default LoginScreen;
